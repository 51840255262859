import React, { useEffect, useState } from "react";

import { Link, Redirect } from "react-router-dom";
import { Route } from "react-router-dom";

import "../../MediaQueries.css";
import "./Sidebar.css";
import "../Dashboard/HorizontalMenu.css";

import { Button } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";

import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import MoneyOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import LanguageIcon from "@material-ui/icons/Language";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";

import { useTranslation } from "react-i18next";
import "../../Translations/i18n";

import MainDashboard from "../Dashboard/MainDashboard";
import Calendar from "../Calendar/Calendar2";
import PricingSection from "../Prices/PricingSection";
import Settings from "../Settings/Settings";
import AllEventsTable from "../Calendar/AllEventsTable";

const Sidebar = () => {
  const [inactive, setInactive] = useState(false);
  const [selection, setSelection] = useState(0);
  const [language, setLanguage] = useState(sessionStorage.getItem("language"));
  const [redirect, setRedirect] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, [width]);

  const { t } = useTranslation();

  if (language == "en") {
    sessionStorage.setItem("language", "en");
  } else {
    sessionStorage.setItem("language", "jp");
  }

  const Logout = () => {
    sessionStorage.clear();
    setRedirect(true);
    window.location.reload();
  };
  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <div>
      <section className="horizontal-menu">
        <div className="searchBar-bellIcon-container"></div>
      </section>
      <div className="app-container-1-wrapper">
        <div className="app-container-1">
          <div
            className={
              inactive
                ? "side-navbar-container-display"
                : "side-navbar-container"
            }
          >
            <div className="side-menu division-class">
              <div>
                <div className="top-section">
                  <h2>{t("Ultra Pricing")}</h2>
                  {
                    <h3
                      onClick={() => setInactive(!inactive)}
                      className={
                        inactive
                          ? "gg-chevron-double-right"
                          : "gg-chevron-double-left"
                      }
                    ></h3>
                  }
                </div>
                <div className="side-nav-divider"></div>

                {/* Profile Section */}

                <section>
                  <div className="sidebar-profile-section">
                    <div className="profile-image-container">
                      <PersonIcon />
                    </div>
                    <div>
                      <p className="Sidebar-profile-name">Admin</p>
                      <p className="Sidebar-profile-email">
                        {sessionStorage.getItem("email")}
                      </p>
                    </div>
                  </div>
                </section>

                <section className="sideMenu-item-container">
                  <div className="sideMenu-items">
                    <Link to="/dashboard/maindashboard">
                      <div className="Both-icon-text-container">
                        <DashboardOutlinedIcon
                          className={
                            selection !== 1
                              ? "menu-icon-color"
                              : "side-nav-item-selected"
                          }
                        />
                        <p
                          className={
                            selection !== 1
                              ? "menu-item-text"
                              : "menu-item-text side-nav-item-selected"
                          }
                          onClick={() => {
                            setSelection(1);
                          }}
                        >
                          {t("Dashboard")}
                        </p>
                      </div>
                    </Link>

                    <Link to="/dashboard/prices">
                      <div className="Both-icon-text-container">
                        <MoneyOutlinedIcon
                          className={
                            selection !== 2
                              ? "menu-icon-color"
                              : "side-nav-item-selected"
                          }
                        />
                        <p
                          className={
                            selection !== 2
                              ? "menu-item-text"
                              : "menu-item-text side-nav-item-selected"
                          }
                          onClick={() => {
                            setSelection(2);
                          }}
                        >
                          {t("Prices")}
                        </p>
                      </div>
                    </Link>
                    <Link to="/dashboard/calendar">
                      <div className="Both-icon-text-container">
                        <CalendarTodayOutlinedIcon
                          className={
                            selection !== 4
                              ? "menu-icon-color"
                              : "side-nav-item-selected"
                          }
                        />
                        <p
                          className={
                            selection !== 4
                              ? "menu-item-text"
                              : "menu-item-text side-nav-item-selected"
                          }
                          onClick={() => {
                            setSelection(4);
                          }}
                        >
                          {t("Calendar")}
                        </p>
                      </div>
                    </Link>
                  </div>

                  <div className="side-nav-divider"></div>
                  <Link to="/dashboard/settings">
                    <div className="Both-icon-text-container">
                      <SettingsIcon
                        className={
                          selection !== 5
                            ? "menu-icon-color"
                            : "side-nav-item-selected"
                        }
                      />
                      <p
                        className={
                          selection !== 5
                            ? "menu-item-text"
                            : "menu-item-text side-nav-item-selected"
                        }
                        onClick={() => {
                          setSelection(5);
                        }}
                      >
                        {t("Settings")}
                      </p>
                    </div>
                  </Link>
                </section>
              </div>
              <div>
                <div className="side-nav-divider"></div>
                <div className="language">
                  <LanguageIcon />
                  <div>
                    <h4>{t("Language")}</h4>
                    <h6>
                      <span
                        className={
                          language === "en" ? "language-selected" : null
                        }
                        onClick={() =>
                          setLanguage("en", window.location.reload())
                        }
                      >
                        English
                      </span>{" "}
                      /{" "}
                      <span
                        className={
                          language === "jp" ? "language-selected" : null
                        }
                        onClick={() =>
                          setLanguage("jp", window.location.reload())
                        }
                      >
                        {/* 日本語 */}
                        Japanese
                      </span>{" "}
                    </h6>
                  </div>
                </div>
                <div className="logout-div">
                  <Button className="logout-button" onClick={Logout}>
                    <LogoutIcon />
                    <h5>{t("Log out")}</h5>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar-container">
            <Route exact path="/dashboard" component={MainDashboard} />
            <Route exact path="/dashboard/events" component={AllEventsTable} />
            <Route
              exact
              path="/dashboard/maindashboard"
              component={MainDashboard}
            />
            <Route path="/dashboard/calendar">
              <Calendar />
            </Route>
            <Route exact path="/dashboard/prices">
              <PricingSection />
            </Route>
            <Route
              exact
              path="/dashboard/prices/pricing"
              component={PricingSection}
            />
            <Route exact path="/dashboard/settings" component={Settings} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
