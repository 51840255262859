import React, { useState } from "react";
import "./Login.css";
import { Button, Grid, IconButton, InputAdornment } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { TextField } from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

import swal from "sweetalert";

import LinearProgress from "@mui/material/LinearProgress";
import { useTranslation } from "react-i18next";

function Login(props) {

  // redirect after login
  const [redirect, setRedirect] = useState(false);

  const { t } = useTranslation();

  // show password flag
  const [showPassword, setShowPassword] = useState(false);

  // state to store login credentials from input 
  const [loginCred, setLoginCred] = useState({
    email: "",
    password: "",
  });

  // loader for login
  const [loader, setLoader] = useState(false);

  // function used to store input information
  const handleChange = (e) => {
    setLoginCred({
      ...loginCred,
      [e.target.name]: e.target.value,
    });
  };

  // function to check the login information
  const checkLogin = (e) => {
    e.preventDefault();
    setLoader(true);
    const URL =process.env.REACT_APP_BACKEND_IP+"/token/"
    fetch(URL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: loginCred.email,
        password: loginCred.password,
      }),
    })
      .then(async (res) => {
        const response = await res.json();
        sessionStorage.setItem("loggedIn", true);
        sessionStorage.setItem("email", response.email);
        sessionStorage.setItem("token", response.token);
        sessionStorage.setItem("userId", response.userId);
        const URL =process.env.REACT_APP_BACKEND_IP+"/users/"+response.userId;
        fetch(URL, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${response.token}`,
          },
        })
          .then(async (res) => {
            const response = await res.json();
            sessionStorage.setItem("accommodationId", response.accommodationId);
            sessionStorage.setItem("pmskey", response.pmskey);
            if(res.status===200){
              setRedirect(true);
              window.location.reload();
              setLoader(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });

        if (!response.non_field_errors) {
          sessionStorage.setItem("language", "en");
        } else {
          swal("Error", "Email/Password is incorrect", "error");
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        swal("Error", "Internal Error, cannot login", "error");
      });
  };

  if (redirect) {
    return (
      <div>
        <Redirect to="/dashboard" />
      </div>
    );
  }

  return (
    <div>
      <div className="login-wrapper">
        <div className="login-card">
          <div className="login-title">
            <h1>U</h1>
            <h3>{t("Log In To Ultra Pricing")}</h3>
          </div>
          <Grid container className="login-inputs" justifyContent="center">
            <form onSubmit={checkLogin}>
              <Grid item sm={12} xs={12}>
                <label htmlFor="email" className="login-input-label">
                  {t("EMAIL")}
                </label>
                <TextField
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={loginCred.email}
                  className="input-field"
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <div className="password-labels">
                  <label htmlFor="password" className="login-input-label">
                    {t("PASSWORD")}
                  </label>
                  <Link to="/forgotpassword">
                    <label className="forgot-pass-label">
                      {t("Forgot Password?")}
                    </label>
                  </Link>
                </div>
                <TextField
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={loginCred.password}
                  className="input-field"
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
                <label className="forgot-pass-label display-none">
                  {t("Passwords don't match")}
                </label>
              </Grid>
              <Grid item sm={12} xs={12} className="login-button-wrapper">
                <div className="linear-progress">
                  {loader ? <LinearProgress /> : null}
                </div>
                <Button type="submit" className="login-button">
                  {t("LogIn")}
                </Button>
                <Link to="/signup">
                  <p>{t("Create a new account?")}</p>
                </Link>
              </Grid>
            </form>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Login;
