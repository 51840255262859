import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import IP from "../../../IP/IP";
import "./StandardPrices.css";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { progress } from "../../../Loader";


function OptionPrices(props) {

  // translatin variable
  const { t } = useTranslation();

  const [dateValue, setDateValue] = useState([null, null]);

  const [columns, setColumns] = useState([
    {
      title: t("Option Name"),
      field: "optionName",
      editable: "never",
    },
    {
      title: t("For 1 person"),
      field: "extraPrice1",
    },
    {
      title: t("For 2 people"),
      field: "extraPrice2",
    },
    {
      title: t("For 3 people"),
      field: "extraPrice3",
    },
    {
      title: t("For 4 people"),
      field: "extraPrice4",
    },
    { title: t("For 5 people"), field: "extraPrice5" },
    { title: t("For 6 people"), field: "extraPrice6" },
  ]);

  const [optionPrices, setOptionPrices] = useState([
    {
      optionName: progress,
      extraPrice1: progress,
      extraPrice2: progress,
      extraPrice3: progress,
      extraPrice4: progress,
      extraPrice5: progress,
      extraPrice6: progress,
    },
  ]);

  const getOptionPrices = () => {
    fetch(`${IP}/price/optionprice?search=${sessionStorage.getItem("accommodationId")}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        setOptionPrices(response.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOptionPrices();
  }, []);

  return (
    <div>
      <div className="standard-prices-wrapper">
        <MaterialTable
          options={{
            search: false,
            toolbar: false,
          }}
          title={null}
          columns={columns}
          data={optionPrices}
          editable={{
            onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
                fetch(`${IP}/price/optionprice/${oldData.optionPriceId}`, {
                  method: "PATCH",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: `Token ${sessionStorage.getItem("token")}`,
                  },
                  body: JSON.stringify({
                    optionName: oldData.optionName,
                    extraPrice1: newData.extraPrice1,
                    extraPrice2: newData.extraPrice2,
                    extraPrice3: newData.extraPrice3,
                    extraPrice4: newData.extraPrice4,
                    extraPrice5: newData.extraPrice5,
                    extraPrice6: newData.extraPrice6,
                    accommodationId: oldData.accommodationId,
                  }),
                })
                  .then(async (res) => {
                    const response = await res.json();
                    if (res.status === 200) {
                      getOptionPrices();
                    } else {
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                resolve();
              }),
          }}
        />
      </div>
    </div>
  );
}

export default OptionPrices;
