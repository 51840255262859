import React from "react";
import { Link } from "react-router-dom";
import "./MainDashboard.css";
import { Grid } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import IP from "../../IP/IP";

import { useState } from "react";
import Chart from "chart.js/auto";
import { progress } from "../../Loader";
import moment from "moment";

const MainDashboard = () => {

  // translation constant - for translating the word
  const { t } = useTranslation();

  // state to store chart data
  const [chartData, setChartData] = useState(null);

  // state to store KPI data
  const [KPIData, setKPIData] = useState(null);

  // store the upcoming events
  const [upcomingEvents, setUpcomingEvents] = useState(null);

  // const for storing the slected value form select in chart
  const [selected, setSelected] = useState(null);

  //  const for the data to be displayed in chart
  const myChart = {
    labels: chartData ? chartData.labels : [],
    datasets: [
      {
        label: t(`${selected === null ? t("revenue") : selected}`),
        // data: [33, 53, 85, 41, 44, 65, 33, 22, 88, 100, 45, 67],
        data: chartData ? chartData.data : [],
        fill: true,
        backgroundColor: "#109cf1",
        hoverBackgroundColor: "#00557b",
      },
    ],
  };

  // function to handle the select option in chart on dashboard
  const handleOptionSelect = (e) => {
    setSelected(e.target.value);
    getChartData(e.target.value);
  };

  // function to show 6 upcomin events from today
  const getLatestEvents = () => {
    // Logic to show 5 upcoming events
    const fromDate = moment().format("YYYY-MM-DD");

    let toDate = moment().add(6,"days").format("YYYY-MM-DD");

    fetch(`${IP}/events/${fromDate}/${toDate}?search=${sessionStorage.getItem("accommodationId")}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        let data = [];
        response.results.map((item) => {
          data = [
            ...data,
            {
              eventName: item.event,
              eventFromDate: new Date(item.fromDate).toDateString(),
              eventToDate: new Date(item.toDate).toDateString(),
            },
          ];
        });
        setUpcomingEvents(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Function for fetching the chart data from the server
  const getChartData = (selected) => {
    setChartData(null);

    fetch(`${IP}/dashboard/graph/${selected === null ? "revenue" : selected}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        setChartData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // function to get Revenue,RevPAR,Occupany,ADR,Units Sold from the server
  const getKPIData = () => {
    setKPIData(null);

    fetch(`${IP}/dashboard/cards`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        setKPIData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getLatestEvents();
    getChartData(selected);
    getKPIData();
  }, []);

  return (
    <div className="dashboard-app">
      <Grid container className="dashboard-container">
        <Grid sm={10} xs={12} item className="chart-event-holder">
          <div className="chart-wrapper">
            <div className="sorter-div">
              <span>{t("Sort By")}</span>
              <select
                name="select"
                id="select"
                className="select-sort-value"
                style={{ color: "#00557b" }}
                onChange={handleOptionSelect}
              >
                <option value="revenue">{t("Select Option")}</option>
                <option value="revenue" selected>
                  {t("Revenue")}
                </option>
                <option value="revpar">{t("RevPAR")}</option>
                <option value="occupancy">{t("Occupancy")}</option>
                <option value="adr">{t("ADR")}</option>
                <option value="unitsold">{t("Units Sold")}</option>
              </select>
            </div>
            {chartData !== null ? (
              <Bar data={myChart} />
            ) : (
              <div className="loader">{progress}</div>
            )}
          </div>
          <div className="events-display">
            <div className="upcoming-events-container">
              <div className="upcoming-events">
                <CalendarTodayOutlinedIcon />
                <h3>{t("Upcoming Events")}</h3>
              </div>
              {upcomingEvents ? (
                <div className="upcoming-events-list">
                  {upcomingEvents.map((data) => {
                    return (
                      <div>
                        <div className="list-item">
                          <p>{data.eventName}</p>
                          {data.eventFromDate === data.eventToDate ? (
                            <div>
                              <small>{data.eventFromDate}</small>
                            </div>
                          ) : (
                            <div>
                              <small>{data.eventFromDate}</small>
                              <small> - </small>
                              <small>{data.eventToDate}</small>
                            </div>
                          )}
                        </div>
                        <div className="vector-line"></div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                progress
              )}
              <div className="read-more">
                <Link to="/dashboard/events">{t("All Events")}</Link>
              </div>
            </div>
            <div className="events-customers">
              <div className="total-customers">
                <div>
                  <h3>
                    {t(`Total ${selected === null ? "Revenue" : selected}`)}
                  </h3>
                  <small>{t("Previous year")}</small>
                  <h5>
                    {chartData === null ? progress : chartData.Total}
                    <span className="up-arrow">&#8593;</span>
                  </h5>
                </div>
                <div>
                  <h2>{chartData === null ? progress : chartData.Total}</h2>
                </div>
              </div>
              <div className="total-events">
                <div>
                  <div>
                    <h3>{t("Total Events")}</h3>
                    <small>{t("Previous year")}</small>
                    <h5>
                      {KPIData === null ? null : KPIData.TotalEvents.previous}
                      <span className="up-arrow">&#8593;</span>
                    </h5>
                  </div>
                </div>
                <div>
                  <h2>
                    {KPIData === null ? progress : KPIData.TotalEvents.current}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid sm={2} xs={12} item className="right-side-items">
          <Link to="/dashboard" className="griditems-link-wrapper">
            <Grid container className="grid-right-items">
              <Grid sm={10} xs={10} item>
                <div>
                  <p>{t("Revenue")}</p>
                  <h3>
                    {KPIData === null ? progress : KPIData.Revenue.current}
                  </h3>
                  <small>{t("Previous year")}</small>
                  <p className="right-item-value">
                    {KPIData === null ? null : KPIData.Revenue.previous}
                    <span className="down-arrow">&#8595;</span>
                  </p>
                </div>
              </Grid>
            </Grid>
          </Link>

          <Link to="/dashboard" className="griditems-link-wrapper">
            <Grid container className="grid-right-items">
              <Grid sm={10} xs={10} item>
                <div>
                  <p>{t("RevPAR")}</p>
                  <h3>
                    {KPIData === null ? progress : KPIData.RevPAR.current}
                  </h3>
                  <small>{t("Previous year")}</small>
                  <p className="right-item-value">
                    {KPIData === null ? null : KPIData.RevPAR.previous}
                    <span className="up-arrow">&#8593;</span>
                  </p>
                </div>
              </Grid>
            </Grid>
          </Link>

          <Link to="/dashboard" className="griditems-link-wrapper">
            <Grid container className="grid-right-items">
              <Grid sm={10} xs={10} item>
                <div>
                  <p>{t("Occupancy")}</p>
                  <h3>
                    {KPIData === null ? progress : KPIData.occupancy.current}
                  </h3>
                  <small>{t("Previous year")}</small>
                  <p className="right-item-value">
                    {KPIData === null ? null : KPIData.occupancy.previous}
                    <span className="up-arrow">&#8593;</span>
                  </p>
                </div>
              </Grid>
            </Grid>
          </Link>

          <Link to="/dashboard" className="griditems-link-wrapper">
            <Grid container className="grid-right-items">
              <Grid sm={10} xs={10} item>
                <div>
                  <p>{t("ADR")}</p>
                  <h3>{KPIData === null ? progress : KPIData.ADR.current}</h3>
                  <small>{t("Previous year")}</small>
                  <p className="right-item-value">
                    {KPIData === null ? null : KPIData.ADR.previous}
                    <span className="up-arrow">&#8593;</span>
                  </p>
                </div>
              </Grid>
            </Grid>
          </Link>

          <Link to="/dashboard" className="griditems-link-wrapper">
            <Grid container className="grid-right-items">
              <Grid sm={10} xs={10} item>
                <div>
                  <p>{t("Units Sold")}</p>
                  <h3>
                    {KPIData === null ? progress : KPIData.UnitSold.current}
                  </h3>
                  <small>{t("Previous year")}</small>
                  <p className="right-item-value">
                    {KPIData === null ? null : KPIData.UnitSold.previous}
                    <span className="up-arrow">&#8593;</span>
                  </p>
                </div>
              </Grid>
            </Grid>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

export default MainDashboard;
