import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./PricingSection.css";

// material ui imports
import Box from "@material-ui/core/Box";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateRangePicker from "@mui/lab/DateRangePicker";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Snackbar,
} from "@material-ui/core";
import moment from "moment";

// Accordian Imports
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

// token and url import
import IP from "../../IP/IP";

import BulkPriceUpdateModal from "./BulkPriceUpdateModal";
import PricingTable from "./PricingTable";
import swal from "sweetalert";
import { progress } from "../../Loader";

// Accordian Functions and Constants
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  padding: "0px 0px",
  "& .Mui-expanded .MuiAccordionSummary-expandIcon ": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const PricingSection = () => {
  // data in the bulk price update table
  const [roomGroupData, setRoomGroupData] = useState([]);

  // translation variable
  const { t } = useTranslation();

  // snackbar for data updated and error msg
  const [snackbarOpen, setSnackBarOpen] = useState(0);
  if (snackbarOpen != 0) {
    setTimeout(() => {
      setSnackBarOpen(0);
    }, 1500);
  }

  // storing prices
  const [prices, setPrices] = useState(null);

  // date range picker inputs
  const [dateValue, setDateValue] = useState([null, null]);

  // accordian states
  const [expanded, setExpanded] = useState("panel2");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // states for PMS select dropdown
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePMSDropdown = () => {
    setAnchorEl(null);
  };

  //  States of KPI button in Filter dropdown
  const [showKPI, setShowKPI] = useState(true);

  // Recommendation show state in filter dropdown
  const [recommend, setRecommend] = useState(false);

  // Option prices store
  const [optionPrices, setOptionPrices] = useState([]);

  // Action button Bulk price update state
  const [priceUpdateModal, setPriceUpdateModal] = useState(false);

  // State for storing the room type names
  const [roomTypes, setRoomTypes] = useState([]);

  // State for individual cell editing
  const [roomsData, setRoomsData] = useState([]);

  // state for option selection
  const [optionSelected, setOptionSelected] = useState(0);

  // prices for table
  const [OTAPrices, setOTAPrices] = useState({});
  let [otherprices, setotherprice] = useState(0);

  // see all price suggestions action
  const [priceRecommendation, setPriceRecommedation] = useState("ota");

  // month count for table
  const [monthCount, setMonthCount] = useState(0);

  // filtered date state
  const [filteredDate, setFilteredDate] = useState([null, null]);

  // prices shown at top of table toggle state
  const [filterApplied, setFilterApplied] = useState(false);

  // On button group clicking i.e previous next today
  let count = monthCount;
  const onButtonGroupChange = (buttonType) => {
    setFilterApplied(false);
    if (buttonType === "today") {
      count = 0;
      setMonthCount(0);
      setFilteredDate([null, null]);
      const newvalues = [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ];
      setDateValue(newvalues);
      getFilteredData(newvalues, "ota", optionSelected, "today");
    } else if (buttonType === "previous") {
      count = count - 1;
      setMonthCount(count);
      const newvalues = [
        moment(filteredDate[0] !== null ? filteredDate[0] : new Date())
          .add(count, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        moment(filteredDate[1] !== null ? filteredDate[1] : new Date())
          .add(count, "month")
          .endOf("month")
          .format("YYYY-MM-DD"),
      ];
      setDateValue(filteredDate[0] !== null ? [null, null] : newvalues);
      getFilteredData(newvalues, "ota", optionSelected, "today");
    } else if (buttonType === "next") {
      count = count + 1;
      setMonthCount(count);
      const newvalues = [
        moment(
          filteredDate[0] !== null ? filteredDate[0] : new Date(),
          "YYYY-MM-DD"
        )
          .add(count, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        moment(
          filteredDate[1] !== null ? filteredDate[1] : new Date(),
          "YYYY-MM-DD"
        )
          .add(count, "month")
          .endOf("month")
          .format("YYYY-MM-DD"),
      ];
      setDateValue(filteredDate[0] !== null ? [null, null] : newvalues);
      getFilteredData(newvalues, "ota", optionSelected, "today");
    }
  };

  // recommendation select from filter
  const showRecommendation = (e) => {
    setRecommend(e.target.value);
  };

  // option select in pricing
  const handleOptionPrice = (e) => {
    setOptionSelected(e.target.value);
    setDateValue([null, null]);
    const newvalues = [
      moment(
        dateValue[0] === null
          ? filteredDate[0]
            ? filteredDate[0]
            : new Date()
          : new Date()
      )
        .add(count, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
        moment(
          dateValue[0] === null
            ? filteredDate[0]
              ? filteredDate[0]
              : new Date()
            : new Date()
        )
          .add(count, "month")
          .endOf("month")
          .format("YYYY-MM-DD"),
    ];
    getFilteredData(newvalues, priceRecommendation, e.target.value);
  };

  // handle the price type
  const handlePriceRecommendation = (type) => {
    setPriceRecommedation(type);
    setDateValue([null, null]);
    const newvalues = [
      moment().startOf("month").format("YYYY-MM-DD"),
      moment().endOf("month").format("YYYY-MM-DD"),
    ];
    setDateValue(newvalues);
    getFilteredData(newvalues, type);
  };

  // get all prices
  const getAllPrices = (priceType = "ota") => {
    const date1 = moment().startOf("month").format("YYYY-MM-DD");
    const date2 = moment().endOf("month").format("YYYY-MM-DD");
    setOTAPrices([]);
    setotherprice(0);

    fetch(
      `${IP}/price/?fromDate=${date1}&toDate=${date2}&kpi=true&recommendation=${recommend}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then(async (res) => {
        const response = await res.json();
        setPrices(response);
        // fetch(`${IP}/rooms/roomgroup?search=${sessionStorage.getItem("userId")}`, {
        fetch(
          `${IP}/rooms/roomgroup?search=${sessionStorage.getItem(
            "accommodationId"
          )}`,
          {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              authorization: `Token ${sessionStorage.getItem("token")}`,
            },
          }
        )
          .then(async (res) => {
            const response = await res.json();
            setRoomsData(response.results);
            setRoomGroupData(
              response.results.map((i) => {
                return {
                  roomGroupName: i.roomGroupName,
                  for1person: 0,
                  for2person: 0,
                  for3person: 0,
                  for4person: 0,
                  for5person: 0,
                  for6person: 0,
                };
              })
            );
            setRoomTypes(
              response.results.map((i) => {
                return i.roomGroupName;
              })
            );

            // fetching option prices
            fetch(
              `${IP}/price/optionprice?search=${sessionStorage.getItem(
                "accommodationId"
              )}`,
              {
                method: "get",
                headers: {
                  "Content-Type": "application/json",
                  authorization: `Token ${sessionStorage.getItem("token")}`,
                },
              }
            )
              .then(async (res) => {
                const response = await res.json();
                setOptionPrices(response.results);
                setOptionSelected(response.results[0]["optionPriceId"]);
                const options = response.results.map((option) => {
                  return {
                    value: option.optionPriceId,
                    label: option.optionName,
                  };
                });
                sessionStorage.setItem("options", JSON.stringify(options));
              })
              .catch((err) => {
                console.log(err);
              });
            response.results.map((item) => {
              fetch(
                `${IP}/price/getota?fromDate=${date1}&toDate=${date2}&optionpriceid=${116}&roomgroupid=${
                  item.roomGroupId
                }`,
                {
                  method: "get",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: `Token ${sessionStorage.getItem("token")}`,
                  },
                }
              )
                .then(async (res) => {
                  const response = await res.json();
                  setOTAPrices((OTAPrices[item.roomGroupName] = response));
                  setotherprice(OTAPrices);
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // select value state
  const onPeriodSelect = (e) => {
    const newvalue = [
      e.target.value.split(",")[0],
      e.target.value.split(",")[1],
    ];
    setDateValue(newvalue);
  };
  // apply filter on click
  const getFilteredData = (
    date,
    type = "ota",
    optionId = optionSelected,
    day,
    filterElement
  ) => {
    setPrices(null);
    setExpanded("panel2");
    setOTAPrices([]);
    setotherprice(0);

    if (filterElement) {
      setMonthCount(0);
      setFilterApplied(true);
    }

    const startdate =
      filteredDate[0] !== null && day !== "today" ? moment(filteredDate[0]).add(count,"months").format("YYYY-MM-DD") : date[0];
    const enddate =
      filteredDate[1] !== null && day !== "today" ? moment(filteredDate[1]).add(count,"months").format("YYYY-MM-DD") : date[1];

    fetch(
      `${IP}/price/?fromDate=${startdate}&toDate=${enddate}&kpi=true&recommendation=${recommend}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then(async (res) => {
        const response = await res.json();
        setPrices(response);
        fetch(
          `${IP}/rooms/roomgroup?${sessionStorage.getItem("accommodationId")}`,
          {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              authorization: `Token ${sessionStorage.getItem("token")}`,
            },
          }
        )
          .then(async (res) => {
            const response = await res.json();
            if (type === "ota") {
              getOTAPrices(optionId, startdate, enddate, roomsData);
            } else {
              getAIPrices(optionId, startdate, enddate, roomsData);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const acceptRecommendations = (date1, date2) => {
    fetch(
      `${IP}/price/acceptrecommondation/${date1}/${date2}/${optionSelected}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then(async (res) => {
        const response = await res.json();
        if (res.status === 200) {
          swal("Success", "All recommended prices accepted", "success");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOTAPrices = (optionId, startdate, enddate, roomgrouparray) => {
    setOTAPrices([]);
    setotherprice(0);
    roomgrouparray.map((item, index) => {
      fetch(
        `${IP}/price/getota?fromDate=${startdate}&toDate=${enddate}&optionpriceid=${optionId}&roomgroupid=${item.roomGroupId}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            authorization: `Token ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then(async (res) => {
          const response = await res.json();
          setOTAPrices((OTAPrices[item.roomGroupName] = response));
          let obj = {};
          obj[item.roomGroupName] = OTAPrices[item.roomGroupName];
          setotherprice(Object.assign(otherprices, obj));
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const getAIPrices = (optionId, startdate, enddate, roomgrouparray) => {
    setOTAPrices([]);
    setotherprice(0);
    roomgrouparray.map((item, index) => {
      fetch(
        `${IP}/price/AI?fromDate=${startdate}&toDate=${enddate}&optionpriceid=${optionId}&roomgroupid=${item.roomGroupId}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            authorization: `Token ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then(async (res) => {
          const response = await res.json();
          setOTAPrices((OTAPrices[item.roomGroupName] = response));
          let obj = {};
          obj[item.roomGroupName] = OTAPrices[item.roomGroupName];
          setotherprice(Object.assign(otherprices, obj));
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  useEffect(() => {
    getAllPrices();
  }, []);

  return (
    <div className="Pricing_Section_Wrapper">
      <Snackbar
        open={snackbarOpen == 1 ? true : false}
        message="Bulk Update Successful"
        className="snackbar-success"
      />
      <Snackbar
        open={snackbarOpen == 2 ? true : false}
        message="Error in Bulk Update"
        className="snackbar-failure"
      />
      <section className="Switch_Link_container">
        <Link style={{ textDecoration: "none" }} to="/dashboard/prices/pricing">
          <p className="Pricing_and_Occupancy_Para1">{t("Pricing")}</p>
        </Link>
      </section>
      <hr className="horizontal-line" />
      <section className="filter-section-accordian">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>{t("Filter")}</Typography>
          </AccordionSummary>
          <div className="accordian-content">
            <div className="filter-section-1">
              <div className="period-selector">
                <label htmlFor="periodSelect">{t("Period")}</label>
                <Select
                  native
                  name="periodSelect"
                  id="periodSelect"
                  className="filter-period-select"
                  onChange={onPeriodSelect}
                >
                  <option value={null}>{t("Custom")}</option>
                  <option
                    value={[
                      moment().format("YYYY-MM-DD"),
                      moment(new Date(), "YYYY-MM-DD")
                        .add(6, "days")
                        .format("YYYY-MM-DD"),
                    ]}
                  >
                    {t("Current Week")}
                  </option>
                  <option
                    value={[
                      moment().format("YYYY-MM-DD"),
                      moment(new Date(), "YYYY-MM-DD")
                        .add(1, "month")
                        .format("YYYY-MM-DD"),
                    ]}
                  >
                    {t("Current Month")}
                  </option>
                  <option
                    value={[
                      moment().format("YYYY-MM-DD"),
                      moment(new Date(), "YYYY-MM-DD")
                        .add(1, "year")
                        .format("YYYY-MM-DD"),
                    ]}
                  >
                    {t("Current Year")}
                  </option>
                  <option
                    value={[
                      moment(new Date(), "YYYY-MM-DD")
                        .add(6, "days")
                        .format("YYYY-MM-DD"),
                      moment(new Date(), "YYYY-MM-DD")
                        .add(13, "days")
                        .format("YYYY-MM-DD"),
                    ]}
                  >
                    {t("Next Week")}
                  </option>
                  <option
                    value={[
                      moment(new Date(), "YYYY-MM-DD")
                        .add(1, "months")
                        .format("YYYY-MM-DD"),
                      moment(new Date(), "YYYY-MM-DD")
                        .add(2, "months")
                        .format("YYYY-MM-DD"),
                    ]}
                  >
                    {t("Next Month")}
                  </option>
                  <option
                    value={[
                      moment(new Date(), "YYYY-MM-DD")
                        .add(1, "year")
                        .format("YYYY-MM-DD"),
                      moment(new Date(), "YYYY-MM-DD")
                        .add(2, "years")
                        .format("YYYY-MM-DD"),
                    ]}
                  >
                    {t("Next Year")}
                  </option>
                </Select>
              </div>
              <div className="Date_Range_Picker_container">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateRangePicker
                    label="Advanced keyboard"
                    value={filteredDate}
                    onChange={(newValue) => {
                      const date1 = moment(newValue[0]).format("YYYY-MM-DD");
                      const date2 = moment(newValue[1]).format("YYYY-MM-DD");
                      setFilteredDate([date1, date2]);
                    }}
                    renderInput={(startProps, endProps) => (
                      <div className="Date_Range_Picker_wrapper">
                        <DateRangeIcon className="DatePicker_icon1" />
                        <input
                          className="date_Picker_input"
                          ref={startProps.inputRef}
                          {...startProps.inputProps}
                          name="fromDate"
                        />
                        <Box>-</Box>
                        <DateRangeIcon className="DatePicker_icon2" />
                        <input
                          className="date_Picker_input"
                          ref={endProps.inputRef}
                          {...endProps.inputProps}
                          name="toDate"
                        />
                      </div>
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="filter-section-2">
              <div className="period-selector">
                <label htmlFor="recommendSelect">
                  {t("Price Recommendation")}
                </label>
                <Select
                  native
                  name="recommendSelect"
                  id="recommendSelect"
                  className="filter-period-select"
                  onChange={showRecommendation}
                >
                  <option value={false}>{t("All prices")}</option>
                  <option value={true}>{t("Only price recommendation")}</option>
                </Select>
              </div>
              <div className="KPI-select">
                <label htmlFor="kpiButton">{t("Show KPI's")}</label>
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined primary button group"
                  className="button-group"
                >
                  <Button
                    variant={showKPI ? "contained" : "outlined"}
                    onClick={() => setShowKPI(true)}
                  >
                    {t("Show KPI's")}
                  </Button>
                  <Button
                    variant={!showKPI ? "contained" : "outlined"}
                    onClick={() => setShowKPI(false)}
                  >
                    {t("Hide KPI's")}
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="apply-filter">
            <Button
              variant="outlined"
              className="filter-change-btn"
              onClick={() => setExpanded("panel2")}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="contained"
              className="filter-change-btn"
              onClick={() =>
                getFilteredData(dateValue, "ota", optionSelected, "", true)
              }
            >
              {t("Apply Filter")}
            </Button>
          </div>
        </Accordion>
      </section>

      <section className="Section2_Container">
        {/* ---------  Prev , Today , Next buttton ----------- */}

        <div className="buttons-wrapper">
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
            className="All_3_buttons_Container"
          >
            <Button
              variant="contained"
              onClick={
                otherprices !== 0 &&
                roomTypes.length === Object.keys(otherprices).length
                  ? () => {
                      onButtonGroupChange("previous");
                    }
                  : null
              }
              name="previous"
              disabled={
                !(
                  otherprices !== 0 &&
                  roomTypes.length === Object.keys(otherprices).length
                )
              }
            >
              {t("Previous")}
            </Button>
            <Button
              variant="outlined"
              onClick={() => onButtonGroupChange("today")}
              name="today"
              disabled={
                !(
                  otherprices !== 0 &&
                  roomTypes.length === Object.keys(otherprices).length
                )
              }
            >
              {t("Today")}
            </Button>
            <Button
              variant="contained"
              onClick={() => onButtonGroupChange("next")}
              name="next"
              disabled={
                !(
                  otherprices !== 0 &&
                  roomTypes.length === Object.keys(otherprices).length
                )
              }
            >
              {t("Next")}
            </Button>
          </ButtonGroup>

          <div className="Action_btn_container">
            <div className="option-price-selector">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Option Prices
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={optionSelected}
                  label="Age"
                  onChange={handleOptionPrice}
                >
                  {optionPrices.map((item) => {
                    return (
                      <MenuItem value={item.optionPriceId}>
                        {item.optionName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <Button
              variant="contained"
              onClick={handleClick}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              {t("Actions")}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClosePMSDropdown}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  setPriceUpdateModal(true);
                  setAnchorEl(false);
                }}
              >
                {t("Bulk price update")}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  acceptRecommendations(
                    moment().startOf("month").format("YYYY-MM-DD"),
                    moment().endOf("month").format("YYYY-MM-DD")
                  )
                }
              >
                {t("Accept recommendations")}
              </MenuItem>
              <MenuItem
                value="ota"
                onClick={() => handlePriceRecommendation("ota")}
              >
                {t("Do not accept recommendations")}
              </MenuItem>
              <MenuItem
                value="AI"
                onClick={() => handlePriceRecommendation("AI")}
              >
                {t("See all recommendations")}
              </MenuItem>
            </Menu>
          </div>
        </div>
      </section>
      <div className="All_Prices_container">
        <center>
          <h3>
            {filteredDate[0] && filteredDate[1] && filterApplied
              ? moment(filteredDate[0]).format("YYYY-MM-DD") +
                "-" +
                moment(filteredDate[1]).format("YYYY-MM-DD")
              : moment(
                  dateValue[0] === null
                    ? filteredDate[0]
                      ? filteredDate[0]
                      : new Date()
                    : new Date()
                )
                  .add(count, "month")
                  .startOf("month")
                  .format("MMMM YYYY")}
          </h3>
        </center>
      </div>
      {otherprices !== 0 &&
      roomTypes.length === Object.keys(otherprices).length ? (
        <PricingTable
          prices={prices}
          roomTypes={roomTypes}
          showKPI={showKPI}
          roomsData={roomsData}
          dateValue={dateValue}
          OTAPrices={otherprices}
          optionSelected={optionSelected}
          onChange={getFilteredData}
        />
      ) : (
        <div className="loader">{progress}</div>
      )}

      <BulkPriceUpdateModal
        openModal={priceUpdateModal}
        onChange={setPriceUpdateModal}
        optionPrices={optionPrices}
        optionPriceId={optionSelected}
        onChange1={getFilteredData}
      />
    </div>
  );
};

export default PricingSection;
