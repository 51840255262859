import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import "./SignupUser.css";

import swal from "sweetalert";

import { LinearProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function SignupUser(props) {
  // translation varaible
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [signupDetails, setSignupDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    setSignupDetails({ ...signupDetails, [e.target.name]: e.target.value });
  };

  const createUser = (e) => {
    e.preventDefault();
    const URL = process.env.REACT_APP_BACKEND_IP +"/users/"
    setLoader(true);
    fetch(URL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first_name: signupDetails.firstName,
        last_name: signupDetails.lastName,
        email: signupDetails.email,
        password: signupDetails.password,
        is_active: true,
      }),
    })
      .then(async (res) => {
        const response = await res.json();
        if (res.status === 201) {
          swal("Success", "User Created Successfully", "success");
          setRedirect(true);
          setLoader(false);
        } else {
          swal("Info", `${response.email}`, "info");
          setLoader(false);
          setSignupDetails({
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
          });
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <div>
      <div className="login-wrapper">
        <div className="login-card">
          <div className="login-title">
            <h1>U</h1>
            <h3>{t("Sign Up To Ultra Pricing")}</h3>
          </div>
          <Grid container className="login-inputs" justifyContent="center">
            <form onSubmit={createUser}>
              <Grid item sm={12} xs={12}>
                <label htmlFor="email" className="login-input-label">
                  {t("FIRSTNAME")}
                </label>
                <TextField
                  type="firstName"
                  id="firstName"
                  name="firstName"
                  placeholder="Firstname"
                  value={signupDetails.firstName}
                  className="input-field"
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <label htmlFor="email" className="login-input-label">
                  {t("LASTNAME")}
                </label>
                <TextField
                  type="lastName"
                  id="lastName"
                  name="lastName"
                  placeholder="Lastname"
                  value={signupDetails.lastName}
                  className="input-field"
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <label htmlFor="email" className="login-input-label">
                  {t("EMAIL")}
                </label>
                <TextField
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={signupDetails.email}
                  className="input-field"
                  variant="outlined"
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <div className="password-labels">
                  <label htmlFor="password" className="login-input-label">
                    {t("PASSWORD")}
                  </label>
                </div>
                <TextField
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={signupDetails.password}
                  className="input-field"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <div className="password-labels">
                  <label htmlFor="password" className="login-input-label">
                    {t("CONFIRM PASSWORD")}
                  </label>
                </div>
                <TextField
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={signupDetails.confirmPassword}
                  className="input-field"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required
                />

                <label
                  className={
                    signupDetails.confirmPassword === signupDetails.password
                      ? "display-none forgot-pass-label"
                      : "error-msg forgot-pass-label"
                  }
                >
                  {t("Passwords don't match")}
                </label>
              </Grid>
              <Grid item sm={12} xs={12} className="login-button-wrapper">
                <div className="linear-progress">
                  {loader ? <LinearProgress /> : null}
                </div>
                <Button type="submit" className="login-button">
                  {t("Create account")}
                </Button>
                <Link to="/login">
                  <p>{t("Log In")}</p>
                </Link>
              </Grid>
            </form>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default SignupUser;
