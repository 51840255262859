import React, { useEffect, useState } from "react";
import "./Settings.css";
import Hotel from "./SettingTabs/Hotel";
import Users from "./SettingTabs/Users";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AreaTypes from "./SettingTabs/AreaTypes";
import OptionPrices from "./SettingTabs/OptionPrices";
import StandardPrices from "./SettingTabs/StandardPrices";
import Button from "@mui/material/Button";
import ImportIcon from "@material-ui/icons/GetApp";
import { Modal } from "@material-ui/core";
import IP from "../../IP/IP";

import axios from "axios";
import {
  Checkbox,
  CircularProgress,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
} from "@mui/material";
import PMSKey from "./SettingTabs/PMSKey";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";

//  tab panel fuction
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// assigning the indexing to the tabs
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// modal style constant

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

function Settings(props) {
  // translation variable
  const { t } = useTranslation();

  // states for PMS select dropdown
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePMSDropdown = () => {
    setAnchorEl(null);
  };

  // table value for tab change
  const [value, setValue] = useState(0);

  // modal open state  for import csv button
  const [openModal, setOpenModal] = useState(false);

  // save user selected file
  const [csv, setCsv] = useState(null);

  // state to open the file upload button
  const [importFile, setImportFile] = useState(false);

  // error in importing sheets
  const [downloadUrl, setDownloadUrl] = useState(0);

  // loader
  const [loader, setLoader] = useState(false);

  const [accommodation, setAccommodation] = useState([]);

  // handle the changed value
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // close modal function
  const handleClose = () => setOpenModal(false);

  // store imported file in state
  const handleInputFile = (e) => {
    setCsv(e.target.files[0]);
  };

  // upload the seleted file
  const uploadFile = () => {
    setLoader(true);
    let form_data = new FormData();
    form_data.append("docfile", csv);
    form_data.append("user", sessionStorage.getItem("userId"));

    let url = `${IP}/uploadCSV/${csv.name.slice(0, -4)}`;
    axios
      .post(url, form_data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          swal("success", "File Import Successfull", "success");
          setLoader(false);
        } else {
          const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
          setDownloadUrl(downloadUrl);
          setOpenModal(true);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const downloadFile = () => {
    const link = document.createElement("a");

    link.href = downloadUrl;

    link.setAttribute("download", "Erros.txt");
    document.body.appendChild(link);

    setOpenModal(true);
    link.click();

    link.remove();
  };

  const getAccommodation = () => {
    fetch(
      `${IP}/accommodation/aad?search=${sessionStorage.getItem(
        "accommodationId"
      )}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then(async (res) => {
        const response = await res.json();
        setAccommodation(
          response.results.map((obj) => {
            return {
              value: obj.id,
              label: obj.accommodationDateAttribute,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(()=>{
    getAccommodation();
  },[])

  return (
    <div>
      <div className="settings-wrapper">
        <div className="settings-card">
          <div className="settings-title">
            <center>
              <h3>{t("Settings")}</h3>
            </center>
            <div className="import-btn-section">
              {!importFile ? (
                <div className="import-btns">
                  <Button
                    variant="contained"
                    onClick={() => setImportFile(true)}
                  >
                    {t("Import CSV")}
                    <ImportIcon />
                  </Button>
                </div>
              ) : (
                <div>
                  <input type="file" id="getFile" onChange={handleInputFile} />
                  <Button variant="contained" onClick={uploadFile} disabled={csv === null? true : false}>
                    {loader ? (
                      <CircularProgress style={{ color: "white" }} />
                    ) : null}
                    {t("Import")}
                  </Button>
                  <IconButton onClick={() => setImportFile(false)}>
                    {" "}
                    &times;
                  </IconButton>
                </div>
              )}
            </div>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label={t("Hotel")} {...a11yProps(0)} />
                  <Tab label={t("Users")} {...a11yProps(1)} />
                  <Tab label={t("Area Types")} {...a11yProps(2)} />
                  <Tab label={t("Standard Prices")} {...a11yProps(3)} />
                  <Tab label={t("Option Prices")} {...a11yProps(4)} />
                  <Tab label={t("PMS API Key")} {...a11yProps(4)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Hotel />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Users />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <AreaTypes />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <StandardPrices accommodation = {accommodation}/>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <OptionPrices />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <PMSKey />
              </TabPanel>
            </Box>
          </div>
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="error-modal-section">
                <h3>{t("Error while importing the file")}</h3>
                <Button onClick={downloadFile}>
                  {t("Click to Download error file")}
                </Button>
                <div className="modal-buttons">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setOpenModal(false);
                      setCsv("");
                      setImportFile(false);
                    }}
                  >
                    {t("Close")}
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Settings;
