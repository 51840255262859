import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./AllEventsTable.css";


function AllEventsTable(props) {
  let [data, setData] = useState([]);
  const { t } = useTranslation();

  let manipulatedResponseArray = [];

  const getAllEvents = () => {
    const URL =process.env.REACT_APP_BACKEND_IP+"/events/?search="+sessionStorage.getItem("accommodationId")+"?&limit=1000&offset=0"
    fetch(URL, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    }).then(async (res) => {
      const response = await res.json();
      response.results.map((item) => {
        const manipulatedResponse = {
          title: item.event,
          startDate: new Date(item.fromDate),
          endDate: new Date(item.toDate),
          id: item.eventId, // unique
          location: item.location,
          notes: item.description,
          impact: item.impact,
        };
        manipulatedResponseArray = [
          ...manipulatedResponseArray,
          manipulatedResponse,
        ];
      });
      setData((data = [...data, ...manipulatedResponseArray]));
    });
  };

  useEffect(()=>{

    getAllEvents();
  },[])

  return (
    <div>
      <div className="events-table-wrapper">
        <div className="table-container">
          <div className="btn-container">
            <Button className="button-1">
              <Link to="/dashboard/calendar">{t("Calendar")}</Link>
            </Button>
          </div>
          <table className="events-table-container">
            <thead>
              <th className="events-table-head">
                <td className="table-data-items">{t("Event Name")}</td>
                <td className="table-data-items">{t("Location/Place")}</td>
                {/* <td className="table-data-items">Type</td> */}
                <td className="table-data-items">{t("Date")}</td>
              </th>
            </thead>
            <tbody>
              {data.map((data) => {
                const startDate = data.startDate.toUTCString();
                const endDate = data.endDate.toUTCString();
                return (
                  <tr className="events-table-data">
                    <td className="table-data-items">{data.title}</td>
                    <td className="table-data-items">{data.location}</td>
                    {/* <td className="table-data-items">{data.id}</td> */}
                    <td className="table-data-items">
                      {startDate.slice(5, 7) +
                        "-" +
                        startDate.slice(8, 11) +
                        "-" +
                        startDate.slice(12, 16) +
                        " to " +
                        endDate.slice(5, 7) +
                        "-" +
                        endDate.slice(8, 11) +
                        "-" +
                        endDate.slice(12, 16)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AllEventsTable;
