import * as React from "react";
import { useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
  ViewState,
  EditingState,
  IntegratedEditing,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  MonthView,
  Appointments,
  AppointmentForm,
  AppointmentTooltip,
  Toolbar,
  ViewSwitcher,
  DayView,
  DateNavigator,
} from "@devexpress/dx-react-scheduler-material-ui";
import "./Calendar.css";

import { Link } from "react-router-dom";

import { appointments } from "./appointments";
import {
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  styled,
} from "@material-ui/core";

import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";

import Slider from "@material-ui/core/Slider";

import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: "0 9.2px 27.5px 0 rgba(0, 0, 0, 0.06)",
  borderRadius: "5px",
};

const Calendar = (props) => {
  // **************************************Calendar UI Component***************************************

  // snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(0);
  if (snackbarOpen != 0) {
    setTimeout(() => {
      setSnackbarOpen(0);
    }, 1500);
  }

  // event loader
  const [addEventLoader, setAddEventLoader] = useState(false);

  const date = new Date();
  const month = date.getMonth() + 1;
  const [currentDate, setCurrentDate] = React.useState(
    date.getFullYear() + "-" + month + "-" + date.getDate()
  );
  let [data, setData] = React.useState([]);
  const [editingOptions, setEditingOptions] = React.useState({
    allowAdding: true,
    allowDeleting: true,
    allowUpdating: true,
    allowDragging: true,
    allowResizing: true,
  });
  const [addedAppointment, setAddedAppointment] = React.useState({});
  const [isAppointmentBeingCreated, setIsAppointmentBeingCreated] =
    React.useState(false);

  const { allowAdding, allowDeleting, allowUpdating, allowResizing } =
    editingOptions;

  const onCommitChanges = React.useCallback(
    ({ added, changed, deleted }) => {
      if (added) {
        const startingAddedId =
          data.length > 0 ? data[data.length - 1].id + 1 : 0;
        setData([...data, { id: startingAddedId, ...added }]);
      }
      if (changed) {
        setData(
          data.map((appointment) =>
            changed[appointment.id]
              ? { ...appointment, ...changed[appointment.id] }
              : appointment
          )
        );
      }
      if (deleted !== undefined) {
        setData(data.filter((appointment) => appointment.id !== deleted));
        const URL = process.env.REACT_APP_BACKEND_IP+"/events/"+deleted
        //deleting the event from server by id
        fetch(URL, {
          method: "delete",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${sessionStorage.getItem("token")}`,
          },
        })
          .then(async (res) => {
            if(res.status===204){
              setSnackbarOpen(3);
            }else{
              setSnackbarOpen(2);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setIsAppointmentBeingCreated(false);
    },
    [setData, setIsAppointmentBeingCreated, data]
  );
  const onAddedAppointmentChange = React.useCallback((appointment) => {
    setAddedAppointment(appointment);
    setIsAppointmentBeingCreated(true);
  });

  const TimeTableCell = React.useCallback(
    React.memo(({ onDoubleClick, ...restProps }) => (
      <WeekView.TimeTableCell
        {...restProps}
        onDoubleClick={allowAdding ? onDoubleClick : undefined}
      />
    )),
    [allowAdding]
  );

  const CommandButton = React.useCallback(
    ({ id, ...restProps }) => {
      if (id === "deleteButton") {
        return (
          <AppointmentForm.CommandButton
            id={id}
            {...restProps}
            disabled={!allowDeleting}
          />
        );
      }
      return <AppointmentForm.CommandButton id={id} {...restProps} />;
    },
    [allowDeleting]
  );

  // const allowDrag = React.useCallback(
  //   () => allowDragging && allowUpdating,
  //   [allowDragging, allowUpdating]
  // );
  const allowResize = React.useCallback(
    () => allowResizing && allowUpdating,
    [allowResizing, allowUpdating]
  );

  const currentDateChange = (currentDate) => {
    setCurrentDate(currentDate);
  };

  // *******************************************************************************************************************

  // Translation Variable

  const { t } = useTranslation();

  // Slider Value Variable
  const [sliderValue, setSliderValue] = React.useState(0);
  const onSliderMove = (event, newValue) => {
    setSliderValue(newValue);
    eventData.impact = newValue;
  };

  // event flags
  let [addEvent, setAddEvent] = React.useState(false);
  let [allEventsFlag, setAllEventsFlag] = React.useState(false);

  // event data storing states
  const [eventData, setEventData] = React.useState({
    event: "",
    dateFrom: "",
    dateTo: "",
    location: "",
    description: "",
    impact: "",
    allDay: true,
  });

  const handleClose = () => setAddEvent(false);

  const captureInput = (e) => {
    setEventData({
      ...eventData,
      [e.target.name]: e.target.value,
    });
  };

  // impact value selector slider
  const sliderMarks = [
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 3,
      label: 3,
    },
    {
      value: 4,
      label: 4,
    },
    {
      value: 5,
      label: 5,
    },
    {
      value: 6,
      label: 6,
    },
    {
      value: 7,
      label: 7,
    },
    {
      value: 8,
      label: 8,
    },
    {
      value: 9,
      label: 9,
    },
    {
      value: 10,
      label: 10,
    },
  ];

  // Event slider in add event popup

  const ImpactSlider = styled(Slider)({
    color: "#109cf1",
    height: 8,
    "& .MuiSlider-track": {
      border: "none",
      height: 8,
      background: "",
    },
    "& .MuiSlider-thumb": {
      height: 20,
      width: 20,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-rail": {
      height: 8,
      borderRadius: 10,
      background:
        "linear-gradient(to right,#1eff00,#60f400,#fffa00,#edf816,#ff3500,#ff0000)",
      opacity: 0.7,
    },
    "& .MuiSlider-mark": {
      position: "relative",
    },
    "& .MuiSlider-markLabel": {
      top: "30px ",
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: "#52af77",
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });

  // Calendar Api Binding :- Event Post to the server(adding an event)
  const addNewEvent = (e) => {
    e.preventDefault();
    setAddEventLoader(true);
    const URL = process.env.REACT_APP_BACKEND_IP+"/events/"
    fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        event: eventData.event,
        fromDate: new Date(eventData.dateFrom),
        toDate: new Date(eventData.dateTo),
        location: eventData.location,
        description: eventData.description,
        impact: eventData.impact,
        user: sessionStorage.getItem("userId"),
        accommodationId: sessionStorage.getItem("accommodationId")
      }),
    })
      .then(async (res) => {
        const response = await res.json();
        if (res.status === 201) {
          setData([...data, response]);
          setAddEvent(false);
          setAddEventLoader(false);
          getAllEvents();
          setSnackbarOpen(1);
        } else {
          setSnackbarOpen(2);
          setAddEventLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarOpen(2);
      });
  };

  // fetching all events from server
  let manipulatedResponseArray = [];

  const getAllEvents = () => {
    const URL =process.env.REACT_APP_BACKEND_IP+"/events/?search="+sessionStorage.getItem("accommodationId")+"?&limit=1000&offset=0"
    fetch(URL, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    }).then(async (res) => {
      const response = await res.json();
      response.results.map((item) => {
        const manipulatedResponse = {
          title: item.event,
          startDate: new Date(item.fromDate),
          endDate: new Date(item.toDate),
          id: item.eventId, // unique
          location: item.location,
          notes: item.description,
          impact: item.impact,
        };
        manipulatedResponseArray = [
          ...manipulatedResponseArray,
          manipulatedResponse,
        ];
      });
      setData((data = [...data, ...manipulatedResponseArray]));
    }).catch((err)=>{
      
    })
  };

  React.useEffect(() => {
    getAllEvents();
  }, []);

  return (
    <div className="calendar-wrapper">
      <Modal
        keepMounted
        open={addEvent}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="popup-modal"
      >
        <Box sx={style}>
          <div className="popup-title-div">
            <h2>{t("Add Event")}</h2>
            <span onClick={handleClose}>
              <CloseIcon />
            </span>
          </div>
          <div className="divider"></div>
          <div className="popup-form-wrapper">
            <form className="popup-form">
              <Grid container>
                <Grid className="textfield-wrapper" item sm={8} xs={8}>
                  <label htmlFor="event">{t("Event")}</label>
                  <input
                    onChange={captureInput}
                    className="textfield-input"
                    id="event"
                    name="event"
                    placeholder="Event"
                    type="text"
                    required
                  />
                </Grid>
                <Grid className="textfield-wrapper" item sm={5} xs={8}>
                  <label htmlFor="dateFrom">{t("From")}</label>
                  <input
                    onChange={captureInput}
                    className="textfield-input"
                    id="dateFrom"
                    name="dateFrom"
                    type="datetime-local"
                    placeholder="date"
                    required
                  />
                </Grid>
                <Grid className="textfield-wrapper" item sm={5} xs={8}>
                  <label htmlFor="dateTo">{t("To")}</label>
                  <input
                    onChange={captureInput}
                    className="textfield-input"
                    id="dateTo"
                    name="dateTo"
                    type="datetime-local"
                    required
                  />
                </Grid>
                <Grid className="textfield-wrapper" item sm={8} xs={8}>
                  <label htmlFor="location">{t("Location")}</label>
                  <input
                    onChange={captureInput}
                    className="textfield-input"
                    id="location"
                    name="location"
                    placeholder="Location"
                    type="text"
                    required
                  />
                </Grid>
                <Grid className="textfield-wrapper" item sm={8} xs={8}>
                  <label htmlFor="description">{t("Description")}</label>
                  <input
                    onChange={captureInput}
                    className="textfield-input"
                    id="description"
                    name="description"
                    placeholder="Description"
                    type="text"
                    required
                  />
                </Grid>
                <Grid className="textfield-wrapper" item sm={6} xs={8}>
                  <Box>
                    <label htmlFor="impact">{t("Impact")}</label>
                    <ImpactSlider
                      id="impact"
                      name="impact"
                      step={1}
                      marks={sliderMarks}
                      min={1}
                      max={10}
                      value={sliderValue}
                      onChange={onSliderMove}
                    />
                  </Box>
                </Grid>
                <Grid item sm={8} xs={8} className="submit-button-wrapper">
                  <button
                    type="button"
                    className="submit-button"
                    onClick={addNewEvent}
                  >
                    {t("Add Event")}
                  </button>
                  {addEventLoader ? (
                    <CircularProgress
                      style={{ width: "30px", height: "30px" }}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </form>
          </div>
        </Box>
      </Modal>

      <React.Fragment>
        <Paper>
          <div className="events-buttons">
            <Button
              className="button-1"
              onClick={() => {
                setAllEventsFlag(!allEventsFlag);
              }}
            >
              {allEventsFlag ? (
                <Link to="/dashboard/calendar">{t("Calendar")}</Link>
              ) : (
                <Link to="/dashboard/events">{t("All Events")}</Link>
              )}
            </Button>
            <Button
              onClick={() => {
                setAddEvent(true, setAllEventsFlag(false));
              }}
            >
              {t("Add Events")}
            </Button>
          </div>
          {/* {!allEventsFlag ? ( */}
          <div className="calendar-div">
            {/* <Route
              exact
              path="/dashboard/calendar/events"
              component={AllEventsTable}
            /> */}
            <Scheduler data={data}>
              <ViewState
                currentDate={currentDate}
                defaultCurrentViewName="Month"
                onCurrentDateChange={currentDateChange}
              />
              <EditingState
                onCommitChanges={onCommitChanges}
                addedAppointment={addedAppointment}
                onAddedAppointmentChange={onAddedAppointmentChange}
              />
              <IntegratedEditing />

              <WeekView
                startDayHour={9}
                endDayHour={19}
                timeTableCellComponent={TimeTableCell}
              />

              <MonthView startDayHour={9} endDayHour={19} />

              <DayView startDayHour={9} endDayHour={19} />

              <Toolbar />
              <DateNavigator navigationButtonComponent={"none"} />
              <ViewSwitcher />

              <Appointments />

              <AppointmentTooltip
                showOpenButton
                showDeleteButton={allowDeleting}
              />
              <AppointmentForm
                commandButtonComponent={CommandButton}
                readOnly={isAppointmentBeingCreated ? false : !allowUpdating}
              />
              {/* <DragDropProvider
                  // allowDrag={allowDrag}
                  allowResize={allowResize}
                /> */}
            </Scheduler>
          </div>
        </Paper>
      </React.Fragment>
      <Snackbar
        open={snackbarOpen == 1 ? true : false}
        message="Event added successfully!"
        className="snackbar-success"
      />
      <Snackbar
        open={snackbarOpen == 2 ? true : false}
        message="Error !!"
        className="snackbar-failure"
      />
      <Snackbar
        open={snackbarOpen == 3 ? true : false}
        message="Event Deleted!"
        className="snackbar-info"
      />
    </div>
  );
};

export default Calendar;
