import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import IP from "../../../IP/IP";
import "./StandardPrices.css";
import { progress } from "../../../Loader";
import { useTranslation } from "react-i18next";
import Select from "react-select";

function StandardPrices(props) {
  // translatin variable
  const { t } = useTranslation();

  // accommodation
  const [accommodation, setAccommodation] = useState(props.accommodation);

  const [columns, setColumns] = useState([
    { title: t("Room Group"), field: "RoomGroupName", editable: "never" },
    {
      title: t("For 1 person"),
      field: "standardPrice1user",
    },
    {
      title: t("For 2 people"),
      field: "standardPrice2user",
    },
    {
      title: t("For 3 people"),
      field: "standardPrice3user",
    },
    {
      title: t("For 4 people"),
      field: "standardPrice4user",
    },
    { title: t("For 5 people"), field: "standardPrice5user" },
    { title: t("For 6 people"), field: "standardPrice6user" },
    {
      title: t("Date Attribute"),
      field: "accommodationDateAttributeName",
      render: (rowData) => (
        <span>{rowData.accommodationDateAttributeName}</span>
      ),
      editComponent: (props, rowData) => (
        <Select
          value={props.label}
          onChange={(e) => {props.rowData["accommodationDateAttribute"] = e.value}}
          options={accommodation}
        />
      ),
    },
  ]);

  const [stdPrices, setStdPrices] = useState([
    {
      RoomGroupName: progress,
      standardPrice1user: progress,
      standardPrice2user: progress,
      standardPrice3user: progress,
      standardPrice4user: progress,
      standardPrice5user: progress,
      standardPrice6user: progress,
      accommodationDateAttributeName: progress,
    },
  ]);

  //get standard prices from the server
  const getStdPrices = () => {
    fetch(
      `${IP}/price/standardprice?limit=100&search=${sessionStorage.getItem(
        "accommodationId"
      )}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then(async (res) => {
        const response = await res.json();
        setStdPrices(response.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getStdPrices();
  }, []);

  return (
    <div>
      <div className="standard-prices-wrapper">
        <MaterialTable
          options={{
            search: false,
            toolbar: false,
            tableLayout: "auto",
          }}
          title={null}
          columns={columns}
          data={stdPrices}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  console.log(newData);
                  fetch(
                    `${IP}/price/standardprice/${oldData.standardPriceId}`,
                    {
                      method: "PATCH",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${sessionStorage.getItem(
                          "token"
                        )}`,
                      },
                      body: JSON.stringify({
                        id: oldData.id,
                        standardPrice1user: newData.standardPrice1user,
                        standardPrice2user: newData.standardPrice2user,
                        standardPrice3user: newData.standardPrice3user,
                        standardPrice4user: newData.standardPrice4user,
                        standardPrice5user: newData.standardPrice5user,
                        standardPrice6user: newData.standardPrice6user,
                        accommodationId: newData.accommodationId,
                        roomGroupId: newData.roomGroupId,
                        accommodationDateAttribute:
                          newData.accommodationDateAttribute,
                      }),
                    }
                  )
                  .then(async (res) => {
                  const response = await res.json();
                  console.log(response,res);
                  if (res.status === 200) {
                    getStdPrices();
                  } else {
                      }
                      console.log(res);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  resolve();
                }, 1000);
              }),
          }}
        />
      </div>
    </div>
  );
}

export default StandardPrices;
