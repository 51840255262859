import {
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  Button,
  Checkbox,
  Grid,
  Snackbar,
} from "@mui/material";
import MaterialTable from "material-table";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import IP from "../../IP/IP";
import moment from 'moment';

function BulkPriceUpdateModal(props) {
  // state to get the selected option
  const [optionSelected, setOptionSelected] = useState(
    props.optionPricSelected
  );

  // bulk price update modal table columns
  const [columns, setColumns] = useState([
    { title: "Room Group", field: "roomGroupName", editable: "never" },
    {
      title: "1 person",
      field: "for1person",
    },
    {
      title: "2 people",
      field: "for2person",
    },
    {
      title: "3 people",
      field: "for3person",
    },
    {
      title: "4 people",
      field: "for4person",
    },
    {
      title: "5 people",
      field: "for5person",
    },
    {
      title: "6 people",
      field: "for6person",
    },
  ]);

  // data in the bulk price update table
  const [newRoomGroupData, setNewRoomGroupData] = useState([]);
  const [roomGroupData, setRoomGroupData] = useState([]);

  // date
  const [updateDates,setUpdateDates] = useState({});

  // translation variable
  const { t } = useTranslation();

  // bulk price update object
  const [bulkPriceObj, setBulkPriceObj] = useState({});
  const [applyBulkActivate, setApplyBulkActivate] = useState(true);

  // loader
  const [loader, setLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);

  // snackbar for data updated and error msg
  const [snackbarOpen, setSnackBarOpen] = useState(0);
  if (snackbarOpen != 0) {
    setTimeout(() => {
      setSnackBarOpen(0);
    }, 1500);
  }

  // select option
  const handleOptionPrice = (e) => {
    setOptionSelected(e.target.value);
    setBulkPriceObj(
      Object.assign(bulkPriceObj, { optionPriceId: e.target.value })
    );
    getRoomData();
  };

  // functon ton handle bulk price update modal
  const handleBulkPriceInput = (e) => {
    const obj = {};
    obj[e.target.name] = moment(e.target.value).format("YYYY-MM-DD");
    setBulkPriceObj(Object.assign(bulkPriceObj, obj));
    setUpdateDates(Object.assign(updateDates, obj));
  };

  let weekdays = [];
  const handleCheckBox = (e) => {
    if (e.target.checked === true) {
      weekdays = [...weekdays, parseInt(e.target.name)];
    } else {
      weekdays = weekdays.filter((o) => {
        return parseInt(e.target.name) !== o;
      });
    }
    setBulkPriceObj(Object.assign(bulkPriceObj, { weekdays: weekdays }));
  };

  // bluk price update apply changes
  const bulkUpdateApply = (bulkUpdate) => {
    setLoader(true);
    setBulkPriceObj(
      Object.assign(bulkPriceObj, { optionPriceId: props.optionPriceId })
    );
    fetch(`${IP}/price/bulkupdate`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        authorization: `Token ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(bulkUpdate),
    })
      .then(async (res) => {
        props.onChange(false);
        setLoader(false);
        if (res.status === 201) {
          setSnackBarOpen(1);
        } else if (res.status === 400) {
          setSnackBarOpen(2);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        setSnackBarOpen(2);
      });
  };

  const getRoomData = () => {
    setTableLoader(true);
    fetch(
      `${IP}/rooms/roomgroup?search=${sessionStorage.getItem(
        "accommodationId"
      )}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then(async (res) => {
        const response = await res.json();
        setTableLoader(false);
        setRoomGroupData(
          response.results.map((i) => {
            return {
              roomGroupName: i.roomGroupName,
              roomGroupId: i.roomGroupId,
              for1person: 0,
              for2person: 0,
              for3person: 0,
              for4person: 0,
              for5person: 0,
              for6person: 0,
              option: "",
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getRoomData();
  }, []);

  return (
    <div>
      {/* 
          Modal for bulk price update
      */}

      <Snackbar
        open={snackbarOpen === 1 ? true : false}
        message="Bulk Update Successful"
        className="snackbar-success"
      />
      <Snackbar
        open={snackbarOpen === 2 ? true : false}
        message="Error in Bulk Update"
        className="snackbar-failure"
      />

      <Dialog open={props.openModal} className="popup-modal" fullScreen>
        <h3 className="modal-title">{t("Bulk Price Update")}</h3>
        <Grid container className="bulk-update-modal">
          <Grid container className="period-weekdays">
            <Grid item sm={5} xs={12} className="period">
              <h4>{t("Period")}</h4>
              <div className="period-inputs">
                <label htmlFor="startDate">{t("Start Date")}</label>
                <input
                  name="fromDate"
                  id="startDate"
                  type="date"
                  onChange={handleBulkPriceInput}
                />
              </div>
              <div className="period-inputs">
                <label htmlFor="startDate">{t("End Date")}</label>
                <input
                  name="toDate"
                  id="EndDate"
                  type="date"
                  onChange={handleBulkPriceInput}
                />
              </div>
            </Grid>
            <Grid item sm={7} xs={12} className="weekdays">
              <h4>{t("Weekdays")}</h4>
              <div className="select-weekdays">
                <Checkbox
                  aria-label="Checkbox demo"
                  color="primary"
                  name={0}
                  id={0}
                  onChange={handleCheckBox}
                />
                <label htmlFor="monday">{t("Monday")}</label>
                <Checkbox
                  aria-label="Checkbox demo"
                  color="primary"
                  name={1}
                  id={1}
                  onChange={handleCheckBox}
                />
                <label htmlFor="tuesday">{t("Tuesday")}</label>
                <Checkbox
                  aria-label="Checkbox demo"
                  color="primary"
                  name={2}
                  id={2}
                  onChange={handleCheckBox}
                />
                <label htmlFor="wednesday">{t("Wednesday")}</label>
                <Checkbox
                  aria-label="Checkbox demo"
                  color="primary"
                  name={3}
                  id={3}
                  onChange={handleCheckBox}
                />
                <label htmlFor="thursday">{t("Thursday")}</label>
                <Checkbox
                  aria-label="Checkbox demo"
                  color="primary"
                  name={4}
                  id={4}
                  onChange={handleCheckBox}
                />
                <label htmlFor="friday">{t("Friday")}</label>
                <Checkbox
                  aria-label="Checkbox demo"
                  color="primary"
                  name={5}
                  id={5}
                  onChange={handleCheckBox}
                />
                <label htmlFor="saturday">{t("Saturday")}</label>
                <Checkbox
                  aria-label="Checkbox demo"
                  color="primary"
                  name={6}
                  id={6}
                  onChange={handleCheckBox}
                />
                <label htmlFor="sunday">{t("Sunday")}</label>
              </div>
            </Grid>
          </Grid>
          <div className="room-groups">
            <div className="table-title-div">
              <h4>{t("Room Group")}</h4>
              <div className="table-title-select">
                <h4>Select Option</h4>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Option Prices
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={optionSelected}
                    onChange={handleOptionPrice}
                    label="Option Prices"
                  >
                    {props.optionPrices.map((item) => {
                      return (
                        <MenuItem value={item.optionPriceId}>
                          {item.optionName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <MaterialTable
              options={{
                search: false,
                maxBodyHeight: 250,
                toolbar: true,
                actionsColumnIndex: -1,
                tableLayout: "auto",
              }}
              isLoading={tableLoader}
              title={null}
              columns={columns}
              data={roomGroupData}
              editable={{
                onBulkUpdate: (changes) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      let roomGroups = [];
                      let options = [];
                      let newDataArray = [];
                      setNewRoomGroupData(
                        Object.values(changes).map((item) => {
                          return item.newData;
                        })
                      );
                      const change = Object.values(changes).map((row) => {
                        options = Object.values(row.newData).slice(1);
                        options = options.map((i) => {
                          return parseInt(i);
                        });
                        roomGroups = [
                          ...roomGroups,
                          {
                            roomGroup: row.newData.roomGroupId,
                            options: options.slice(1, 7),
                          },
                        ];
                        setBulkPriceObj(
                          Object.assign(bulkPriceObj, {
                            roomGroups: roomGroups,
                            optionPriceId: optionSelected,
                            weekdays: weekdays.length === 0 ? [] : weekdays
                          })
                        );
                        newDataArray = [...newDataArray, row["newData"]];
                      });
                      setRoomGroupData(newDataArray);
                      resolve();
                      setApplyBulkActivate(false);
                    }, 1000);
                  }),
              }}
            />
          </div>
        </Grid>
        <div className="submit-cancel-btn">
          <Button
            variant="outlined"
            onClick={() => {
              props.onChange(false);
              setBulkPriceObj({});
              setNewRoomGroupData([]);
            }}
          >
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            disabled={applyBulkActivate}
            onClick={() => {bulkUpdateApply(bulkPriceObj)
            props.onChange1(Object.values(updateDates),"ota",optionSelected,"",false);
            }}
          >
            {t("Apply Changes")}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default BulkPriceUpdateModal;
