import { ActionTypes } from "../Constants/action-types"

const initialState = {
    todayPrices : {
        id:0,
        name:"apurv"
    }
}

export const todayPrices = (state = initialState, {type,payload}) => {
    switch(type){
        case ActionTypes.GET_TODAY_PRICES:
            return state
        default:
            return state
    }
}