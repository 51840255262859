import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import IP from "../../../IP/IP";

import { progress } from "../../../Loader";
import { useTranslation } from "react-i18next";

function AreaTypes(props) {

  // translatin variable
  const { t } = useTranslation();

  const [columns, setColumns] = useState([
    { title: t("Room Group"), field: "roomGroupName" },
    {
      title: t("Room Group Id"),
      field: "roomGroupId",
    },
    { title: t("Number of Rooms"), field: "NoOfrooms" },
  ]);

  const getRoomTypes = () => {
    fetch(`${IP}/rooms/roomgroup?search=${sessionStorage.getItem("accommodationId")}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        setRoomGroupData(response.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getRoomTypes();
  }, []);

  const [roomGroupData, setRoomGroupData] = useState([
    {
      roomGroupName: progress,
      roomGroupId: progress,
      NoOfrooms: progress,
    },
  ]);

  return (
    <div>
      <MaterialTable
        options={{
          search: false,
          toolbar: false,
        }}
        title={null}
        columns={columns}
        data={roomGroupData}
      />
    </div>
  );
}

export default AreaTypes;
