import React, { useEffect, useState } from "react";
import { Button, Grid, Snackbar, TextField } from "@material-ui/core";
import "./Hotel.css";
import IP from "../../../IP/IP";
import { useTranslation } from "react-i18next";
import { progress } from "../../../Loader";

function Hotel(props) {
  // translatin variable
  const { t } = useTranslation();

  const [details, setDetails] = useState({
    accommodationName: "",
    postCode: "",
    address: "",
  });

  // snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(0);
  if (snackbarOpen != 0) {
    setTimeout(() => {
      setSnackbarOpen(0);
    }, 1500);
  }

  const saveDetails = (e) => {
    e.preventDefault();

    fetch(`${IP}/users/accommodation/${sessionStorage.getItem("accommodationId")}`, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        authorization: `Token ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(details),
    })
      .then(async (res) => {
        const response = await res.json();
        if (res.status === 200) {
          setSnackbarOpen(1);
        } else {
          setSnackbarOpen(2);
        }
      })
      .catch((err) => {
          setSnackbarOpen(2);
          console.log(err);
      });
  };

  const getAccommodation = () => {
    fetch(`${IP}/users/accommodation/${sessionStorage.getItem("accommodationId")}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        authorization: `Token ${sessionStorage.getItem("token")}`,
      },
      // body: JSON.stringify(details),
    })
      .then(async (res) => {
        const response = await res.json();
        // setSnackbarOpen(1);
        setDetails({
          accommodationName: response.accommodationName,
          postCode: response.postCode,
          address: response.address,
        })
      })
      .catch((err) => {
        // setSnackbarOpen(2);
        console.log(err);
      });
  }

  useEffect(() => {
    getAccommodation();
  }, []);

  return (
    <div>
      <form onSubmit={saveDetails}>
        <Grid container className="hotel-inputs-container">
          <Grid item sm={12} xs={12} className="hotel-label-input-group">
            <label htmlFor="hotelName">{t("Hotel Name")}</label>
            <TextField
              id="hotelName"
              name="hotelName"
              onChange={(e) =>
                setDetails({
                  ...details,
                  accommodationName: e.target.value,
                })
              }
              value={details.accommodationName}
              required
            />
          </Grid>
          <Grid item sm={12} xs={12} className="hotel-label-input-group">
            <label htmlFor="postCode">{t("Postcode")}</label>
            <TextField
              id="postCode"
              name="postCode"
              onChange={(e) =>
                setDetails({
                  ...details,
                  postCode: e.target.value,
                })
              }
              value={details.postCode}
              required
            />
          </Grid>
          <Grid item sm={12} xs={12} className="hotel-label-input-group">
            <label htmlFor="city">{t("Address")}</label>
            <TextField
              id="address"
              name="address"
              onChange={(e) =>
                setDetails({
                  ...details,
                  address: e.target.value,
                })
              }
              value={details.address}
              required
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Button className="save-btn" type="submit">
              {t("Save")}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={snackbarOpen == 1 ? true : false}
        message="Accommodation updated!"
        className="snackbar-success"
      />
      <Snackbar
        open={snackbarOpen == 2 ? true : false}
        message="Error !!"
        className="snackbar-failure"
      />
      <Snackbar
        open={snackbarOpen == 3 ? true : false}
        message="Event Deleted!"
        className="snackbar-info"
      />
    </div>
  );
}

export default Hotel;
