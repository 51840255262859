import {
  Box,
  Button,
  Grid,
  Modal,
} from "@mui/material";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IP from "../../IP/IP";
import { progress } from "../../Loader";

// modal Styling
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: "0 9.2px 27.5px 0 rgba(0, 0, 0, 0.06)",
  borderRadius: "5px",
};

function IndividualCellPopup(props) {
  // individual price update modal table column data state
  const [OTAPriceUpdateColumns, setOTAPriceUpdateColumns] = useState([
    // { title: "", field: "name", editable: "never" },
    { title: "For 1 person", field: "optionprice1user" },
    { title: "For 2 people", field: "optionprice2user" },
    { title: "For 3 people", field: "optionprice3user" },
    { title: "For 4 people", field: "optionprice4user" },
    { title: "For 5 people", field: "optionprice5user" },
    { title: "For 6 people", field: "optionprice6user" },
  ]);

  // individual price update modal table row data state
  const [OTAPriceData, setOTAPriceData] = useState([
    {
      optionprice1user: progress,
      optionprice2user: progress,
      optionprice3user: progress,
      optionprice4user: progress,
      optionprice5user: progress,
      optionprice6user: progress,
    },
  ]);

  // translation variable
  const { t } = useTranslation();

  // State for individual cell editing
  const [AIPrices, setAIPrices] = useState([]);
  const [dayOTAPrices, setDayOTAPrices] = useState([]);

  // loader
  const [loader,setLoader] = useState(false);

  // snackbar for data updated and error msg
  const [snackbarOpen, setSnackBarOpen] = useState(0);
  if (snackbarOpen != 0) {
    setTimeout(() => {
      setSnackBarOpen(0);
    }, 1500);
  }

  const getOTADataByDate = () => {
    fetch(
      `${IP}/price/ota?fromDate=${props.date}&toDate=${props.date}&optionpriceid=${props.optionSelected}&roomgroupid=${props.roomId}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then(async (res) => {
        const response = await res.json();
        setOTAPriceData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOTADataByDate();
  }, []);

  return (
    <Modal
      keepMounted
      open={props.roomFlag}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      className="popup-modal"
      fullWidth={true}
      maxWidth="lg"
    >
      <Box sx={style} className="create-user-modal-wrapper">
        <div>
          <h3 className="modal-title">
            {t("Price Update of ") + props.priceUpdateRoomName}
          </h3>
        </div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
          <Grid container className="price-update-table">
            <MaterialTable
              options={{
                search: false,
                maxBodyHeight: 300,
                paginationPosition: "none",
                toolbar: false,
              }}
              isLoading={loader}
              title={null}
              columns={OTAPriceUpdateColumns}
              data={OTAPriceData}
              editable={{
                onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                    setLoader(true);
                    setTimeout(() => {
                      fetch(`${IP}/price/ota/${oldData.id}`, {
                        method: "PATCH",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Token ${sessionStorage.getItem(
                            "token"
                          )}`,
                        },
                        body: JSON.stringify(newData),
                      })
                        .then(async (res) => {
                          const response = await res.json();
                          if (res.status === 200) {
                            setOTAPriceData([newData]);
                            setLoader(false)
                          } else {
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }, 1000);
                    resolve();
                  }),
              }}
            />
          </Grid>
        </Box>
        <div className="submit-cancel-btn-2">
          <Button variant="outlined" onClick={() => props.onChange(false)}>
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              props.onChange(false);
              props.onChange1(props.dateValue)
              setDayOTAPrices([]);
            }}
          >
            {t("Apply Changes")}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default IndividualCellPopup;
