import { Button, Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


function ForgotPasswrod(props) {
  const [email, setEmail] = useState("");

  const { t } = useTranslation();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const forgotPassword = (email) => {
    const URL =process.env.REACT_APP_BACKEND_IP+"/users/forgotPassoword/"+email
    fetch(URL, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        if(res.status===200){
          alert("Reset Password link sent to "+email)
        }else{
          alert("Error sending the email !!")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="login-wrapper">
        <div className="login-card">
          <div className="login-title">
            <h1>U</h1>
            <h3>{t("Reset Password")}</h3>
          </div>
          <div className="forgot-password">
            <Grid item sm={12} xs={12}>
              <label htmlFor="email" className="login-input-label">
                {t("EMAIL")}
              </label>
              <TextField
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={email}
                className="input-field"
                onChange={handleChange}
                variant="outlined"
              />
              <label className="forgot-pass-label display-none">
                {t("passwords don't match")}
              </label>
            </Grid>
            <Grid item sm={12} xs={12} className="login-button-wrapper">
              <Button
                type="submit"
                className="login-button"
                onClick={() => forgotPassword(email)}
              >
                {t("Send Reset Password Email")}
              </Button>
              <Link to="/login">
                <p>{t("Log In")}</p>
              </Link>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswrod;
