import {
  InputAdornment,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import IP from "../../../IP/IP";
import "./PMSKey.css";
import ImportIcon from "@material-ui/icons/GetApp";
import { progress } from "../../../Loader";

const PMSKey = (props) => {
  // translatin variable
  const { t } = useTranslation();

  // PMS api key state
  const [PMSKey, setPMSKey] = useState(sessionStorage.getItem("pmskey"));

  // selected PMS from dropdown
  const [PMSSelected, setPMSSelected] = useState("");

  // detecting the Key edit click
  const [editFlag, setEditFlag] = useState(false);

  // loader state
  const [loader, setLoader] = useState(false);

  const getUser = () => {
    fetch(`${IP}/users/${sessionStorage.getItem("userId")}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${sessionStorage.getItem("token")}`,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        if (res.status === 200) {
          setPMSKey(response.pmskey);
          sessionStorage.setItem("pmskey", response.pmskey);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAPIKeyInput = (e) => {
    e.preventDefault();
    setLoader(true);
    fetch(`${IP}/users/${sessionStorage.getItem("userId")}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        pmskey: PMSKey,
      }),
    })
      .then(async (res) => {
        const response = await res.json();
        if (res.status === 200) {
          swal("Success", "PMS key uploaded", "success");
          setEditFlag(false);
          getUser();
          setLoader(false);
        } else {
          swal("Error", "PMS key not uploaded", "error");
          setLoader(false);
        }
      })
      .catch((err) => {
        swal("Error", "PMS key not uploaded", "error");
      });
  };

  // function to get staysee data
  const getStayseeData = () => {
    fetch(`${IP}/getAccommodation/2022-03-01/2022-03-28`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          swal("Success", "Import Successful", "success");
        } else {
          swal("Success", "Import Failed", "success");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    fetch(`${IP}/getrooms`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          swal("Success", "Import Successful", "success");
        } else {
          swal("Success", "Import Failed", "success");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectPMS = (e) => {
    setPMSSelected(e.target.value);
  };

  const editKey = () => {
    setEditFlag(true);
  };

  return (
    <div className="pms">
      <div className="pms-select-dropdown">
        <label htmlFor="pms" className="pms-label">
          Select your PMS
        </label>
        <Select id="pms" className="select-pms" onChange={handleSelectPMS}>
          <MenuItem value="0">Staysee</MenuItem>
          <MenuItem value="1">PMS 2</MenuItem>
          <MenuItem value="2">PMS 3</MenuItem>
          <MenuItem value="3">PMS 4</MenuItem>
        </Select>
      </div>
      <form onSubmit={handleAPIKeyInput}>
        {editFlag ? (
          <div className="PMS-key-interface">
            <label htmlFor="PMSKeyInput"> {t("Your PMS API Key")} </label>
            <TextField
              variant="outlined"
              id="PMSKeyInput"
              fullWidth
              onChange={(e) => setPMSKey(e.target.value)}
              required
              value={PMSKey}
              InputProps={{
                endAdornment: loader ? (
                  <InputAdornment position="end">{progress}</InputAdornment>
                ) : null,
              }}
            >
              {" "}
            </TextField>
            <Button variant="contained" type="submit">
              {t("Save")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setEditFlag(false);
              }}
            >
              {t("Cancel")}
            </Button>
          </div>
        ) : (
          <div className="PMS-key-interface-text">
            <label htmlFor="PMSKeyInput"> {t("Your PMS API Key")} :- </label>
            <div className="key-edit">
              <h6>{PMSKey}</h6>
              <i className="fa fa-edit" onClick={editKey}></i>
            </div>
          </div>
        )}
        <div>
          <Button
            variant="contained"
            onClick={getStayseeData}
            id="basic-button"
            aria-haspopup="true"
          >
            {t("Import from PMS")}
            <ImportIcon />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PMSKey;
