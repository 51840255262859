import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Snackbar,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import MaterialTable from "material-table";
import CloseIcon from "@material-ui/icons/Close";
import "./Users.css";
import IP from "../../../IP/IP";

import { useEffect } from "react";
import { progress } from "../../../Loader";
import { useTranslation } from "react-i18next";

import swal from "sweetalert";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: "0 9.2px 27.5px 0 rgba(0, 0, 0, 0.06)",
  borderRadius: "5px",
};

function Users(props) {
  // translation variable
  const { t } = useTranslation();

  // dialog box flags/Modal flags
  const [createUserDialog, setCreateUserDialog] = useState(false);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(0);

  // password show hide state
  const [showPassword, setShowPassword] = useState(false);

  // material table columns
  const [columns, setColumns] = useState([
    { title: t("Firstname"), field: "first_name" },
    { title: t("Lastname"), field: "last_name" },
    {
      title: t("E-mail"),
      field: "email",
    },
    {
      title: t("Role"),
      field: "role",
    },
  ]);

  // used to store material table state i.e users
  const [users, setUsers] = useState([
    {
      first_name: progress,
      last_name: progress,
      email: progress,
      role: progress,
    },
  ]);

  // state that stores data to be sent to server for new user creation
  const [addNewUser, setAddNewUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    is_active: true,
    role: "",
    pmskey: "Add a key here",
  });

  // confirm password state
  const [confirmPassword, setConfirmPassword] = useState("");

  // change password popup states
  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);

  const getAllUsers = () => {
    fetch(
      `${IP}/users/AccommodationId?search=${sessionStorage.getItem(
        "accommodationId"
      )}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then(async (res) => {
        const response = await res.json();
        setUsers(response.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const addUser = (e) => {
    e.preventDefault();

    fetch(`${IP}/users/create/accommodationuser`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        authorization: `Token ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(addNewUser),
    })
      .then(async (res) => {
        const response = await res.json();
        if (res.status == 201) {
          setSnackbarOpen(1);
          setCreateUserDialog(false);
          getAllUsers();
        } else {
          setSnackbarOpen(2);
          swal("Email", response.email[0], "error");
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarOpen(2);
      });
  };

  const changePassword = (e) => {
    e.preventDefault();
    fetch(`${IP}/users/${sessionStorage.getItem("userId")}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: `Token ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({password:confirmNewPassword}),
    })
      .then(async (res) => {
        const response = await res.json();
        console.log(res);
        if (res.status == 200) {
          setSnackbarOpen(3);
          setChangePasswordDialog(false);
        } else {
          setSnackbarOpen(4);
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarOpen(4);
      });
  };

  const handleChangePassword = (e) => {
    if (e.target.name === "newPassword") {
      setNewPassword(e.target.value);
    } else if (e.target.name === "confirmPassword") {
      setConfirmNewPassword(e.target.value);
    }
  };

  if (snackbarOpen != 0) {
    setTimeout(() => {
      setSnackbarOpen(0);
    }, 2000);
  }

  return (
    <div>
      {/* Create User modal */}
      <Modal
        keepMounted
        open={createUserDialog}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="popup-modal"
      >
        <Box sx={style} className="create-user-modal-wrapper">
          <Grid container className="create-user-modal">
            <Grid item sm={12} xs={12} className="modal-title-container">
              <h2>{t("Create a new user")}</h2>
              <IconButton
                onClick={() => {
                  setCreateUserDialog(false);
                  setAddNewUser({
                    first_name: "",
                    last_name: "",
                    email: "",
                    password: "",
                    is_active: true,
                    role: "",
                    pmskey: "",
                  });
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <form onSubmit={addUser}>
              <Grid container className="create-user-input-container">
                <Grid item sm={9} xs={11} className="create-user-input">
                  <label htmlFor="forename">{t("Firstname") + "*"}</label>
                  <TextField
                    required
                    id="forename"
                    name="forename"
                    onChange={(e) =>
                      setAddNewUser({
                        ...addNewUser,
                        first_name: e.target.value,
                      })
                    }
                    value={addNewUser.first_name}
                  />
                </Grid>
                <Grid item sm={9} xs={11} className="create-user-input">
                  <label htmlFor="lastname">{t("Lastname") + "*"}</label>
                  <TextField
                    required
                    id="lastname"
                    name="lastname"
                    onChange={(e) =>
                      setAddNewUser({
                        ...addNewUser,
                        last_name: e.target.value,
                      })
                    }
                    value={addNewUser.last_name}
                  />
                </Grid>
                <Grid item sm={9} xs={11} className="create-user-input">
                  <label htmlFor="email">{t("E-mail") + "*"}</label>
                  <TextField
                    required
                    id="email"
                    name="email"
                    onChange={(e) =>
                      setAddNewUser({
                        ...addNewUser,
                        email: e.target.value,
                      })
                    }
                    value={addNewUser.email}
                  />
                </Grid>
                <Grid item sm={9} xs={11} className="create-user-input">
                  <label htmlFor="password">{t("Password") + "*"}</label>
                  <TextField
                    required
                    id="password"
                    name="password"
                    onChange={(e) =>
                      setAddNewUser({
                        ...addNewUser,
                        password: e.target.value,
                      })
                    }
                    type={showPassword ? "text" : "password"}
                    value={addNewUser.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={9} xs={11} className="create-user-input">
                  <label htmlFor="confirmPassword">
                    {t("Confirm Password") + "*"}
                  </label>
                  <TextField
                    required
                    id="confirmPassword"
                    name="confirmPassword"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    value={confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={9} xs={11} className="create-user-input">
                  <label htmlFor="role">{t("Role") + "*"}</label>
                  <TextField
                    required
                    id="role"
                    name="role"
                    onChange={(e) =>
                      setAddNewUser({
                        ...addNewUser,
                        role: e.target.value,
                      })
                    }
                    value={addNewUser.role}
                  />
                </Grid>
                {confirmPassword === addNewUser.password ? null : (
                  <Grid
                    item
                    sm={9}
                    xs={11}
                    className="password-error"
                    justifyContent="right"
                  >
                    <label className="error-msg">Passwords didn't match</label>
                  </Grid>
                )}
              </Grid>
              <Grid container className="user-modal-btn-container">
                <Button
                  variant="outlined"
                  className="user-modal-btn"
                  onClick={() => {
                    setCreateUserDialog(false);
                    setAddNewUser({
                      first_name: "",
                      last_name: "",
                      email: "",
                      password: "",
                      is_active: true,
                      role: "",
                      pmskey: "",
                    });
                    setConfirmPassword("");
                  }}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  variant="contained"
                  // disabled={true}
                  className="user-modal-btn"
                  color="primary"
                  type="submit"
                >
                  {t("Create New User")}
                </Button>
              </Grid>
            </form>
          </Grid>
        </Box>
      </Modal>

      {/* Change Password Modal */}
      <Modal
        keepMounted
        open={changePasswordDialog}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="popup-modal"
      >
        <Box sx={style} className="create-user-modal-wrapper">
          <Grid container className="create-user-modal">
            <Grid item sm={12} xs={12} className="modal-title-container">
              <h2>{t("Change my password")}</h2>
              <IconButton onClick={() => setChangePasswordDialog(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <form onSubmit={changePassword}>
              <Grid container className="create-user-input-container">
                <Grid item sm={9} xs={11} className="create-user-input">
                  <label htmlFor="newPassword">{t("New Password")}</label>
                  <TextField
                    required
                    id="newPassword"
                    name="newPassword"
                    type={showPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={handleChangePassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={9} xs={11} className="create-user-input">
                  <label htmlFor="confirmPassword">
                    {t("Confirm Password")}
                  </label>
                  <TextField
                    required
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    value={confirmNewPassword}
                    onChange={handleChangePassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container className="user-modal-btn-container">
                <Button
                  variant="outlined"
                  className="user-modal-btn"
                  onClick={() => setChangePasswordDialog(false)}
                >
                  {"Cancel"}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={newPassword === null ? true : newPassword !== confirmNewPassword}
                  className="user-modal-btn"
                  type="submit"
                >
                  {t("Change My Password")}
                </Button>
              </Grid>
            </form>
          </Grid>
        </Box>
      </Modal>

      {/* User Tab */}
      <Grid container className="users-main-container">
        <Grid item sm={12} xs={12} className="users-btn-container">
          <Button
            className="users-btns"
            onClick={() => setChangePasswordDialog(!changePasswordDialog)}
          >
            {t("Change My Password")}
          </Button>
          <Button
            className="users-btns"
            onClick={() => setCreateUserDialog(!createUserDialog)}
          >
            {t(" Add User")}
          </Button>
        </Grid>
        <Grid item sm={12} xs={12}>
          <MaterialTable
            title={null}
            columns={columns}
            data={users}
            options={{ toolbar: false }}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen == 1 ? true : false}
        message="User Created Successfully!"
        className="snackbar-success"
      />
      <Snackbar
        open={snackbarOpen == 2 ? true : false}
        message="Error creating the user!"
        className="snackbar-failure"
      />
      <Snackbar
        open={snackbarOpen == 3 ? true : false}
        message="Password changed successfully!"
        className="snackbar-failure"
      />
      <Snackbar
        open={snackbarOpen == 4 ? true : false}
        message="Error changing the password!"
        className="snackbar-failure"
      />
    </div>
  );
}

export default Users;
