import React, { Suspense, useEffect, useState } from "react";
import "./App.css";

import { Route, Switch } from "react-router-dom";

import Sidebar from "./Components/Sidebar/Sidebar";
import Login from "./Components/Login/Login";
import SignupUser from "./Components/Login/SignupUser";
import ForgotPassword from "./Components/Login/ForgotPassword";

function App() {
  const loggedIn = sessionStorage.getItem("loggedIn");
  const [translation, setTranslations] = useState();

  console.log(sessionStorage)

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND+"/translate/?limit=1000&offset=0", {
      method: "get",
    })
      .then(async (res) => {
        const response = await res.json();
        setTranslations(response.results);
        sessionStorage.setItem(
          "translations",
          JSON.stringify(response.results)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Suspense fallback="loading">
      <div className="App">
        {loggedIn === "true" ? (
          <Switch>
            <Route path="/dashboard">
              <Sidebar />
            </Route>
            <Route component={Sidebar} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/signup" component={SignupUser} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/login" component={Login} />
            <Route component={Login} />
          </Switch>
        )}
      </div>
    </Suspense>
  );
}

export default App;
