import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// import { TRANSLATIONS_JP } from "./jp/jp";
import { TRANSLATIONS_EN } from "./en/en";

let obj = {
  english: "japanese",
};

if(!sessionStorage.getItem("translations")){

  sessionStorage.setItem("translations", JSON.stringify([obj]));
}
let translate = JSON.parse(sessionStorage.getItem("translations"));

if (sessionStorage.getItem("loggedIn") === "true") {
  translate.map((data) => {
    const obj1 = { [data.english]: data.japanese };

    obj = { ...obj, ...obj1 };
  });
}

const TRANSLATIONS_JP = obj;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      jp: {
        translation: TRANSLATIONS_JP,
      },
    },
  });
i18n.changeLanguage(sessionStorage.getItem("language"));
