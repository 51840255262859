import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "@material-ui/icons/Edit";
import IP from "../../IP/IP";
import PersonIcon from "@material-ui/icons/Person";
import IndividualCellPopup from "./IndividualCellPopup";
import { progress } from "../../Loader";

function PricingTable(props) {
  // translation variable
  const { t } = useTranslation();

  // State for individual cell editing
  const [priceUpdateRoomName, setPriceUpdateRoomName] = useState(false);
  const [byDate, setByDate] = useState([]);
  const [priceUpdateRoomId, setPriceUpdateRoomId] = useState(0);
  // Individual Room group price update modal
  const [roomPriceUpdate, setRoomPriceUpdate] = useState(false);

  // Column click popup modal open function
  const tableColumnClicked = (date, room, roomId) => {
    setPriceUpdateRoomName(room);
    setByDate(date);
    setRoomPriceUpdate(true);
    setPriceUpdateRoomId(roomId);
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <div
        className="table-grid-wrapper"
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${
            props.prices === null
              ? 1
              : props.prices.length + 1 < 7
              ? 7
              : props.prices.length + 1
          },1fr)`,
        }}
      >
        <div className="table-grid1">
          <div className="table-grid-column1">
            <p>{t("Date/Category")} </p> <p>&nbsp;</p>
          </div>
          {props.showKPI ? (
            <div className="table-grid-column1">
              <p> {t("Units Sold")}</p>
              <p>{t("Revenue")}</p>
              <p>{t("ADR")}</p>
              <p>{t("RevPAR")}</p>
              <p>{t("Occupancy")}</p>
            </div>
          ) : null}
          {props.roomTypes.map((item) => {
            return (
              <div className="table-grid-column1-wrapper">
                <div className="table-grid-column1">
                  <p>{t(item.charAt(0).toUpperCase() + item.slice(1))}</p>
                  <div className="people">
                    1 x <PersonIcon fontSize="small" />
                  </div>
                  <div className="people">
                    2 x <PersonIcon fontSize="small" />
                  </div>
                  <div className="people">
                    3 x <PersonIcon fontSize="small" />
                  </div>
                  <div className="people">
                    4 x <PersonIcon fontSize="small" />
                  </div>
                  <div className="people">
                    5 x <PersonIcon fontSize="small" />
                  </div>
                  <div className="people">
                    6 x <PersonIcon fontSize="small" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {props.prices !== null
          ? props.prices.map((item, dateIndex) => {
              return (
                <div className="table-grid">
                  <div className="table-grid-column2">
                    <p>{t(item.day)}</p>
                    <p>{item.date}</p>
                  </div>
                  {props.showKPI ? (
                    <div
                      className="table-grid-column2"
                      style={{
                        backgroundColor: `${"light" + item.Revpar.colour}`,
                      }}
                    >
                      <p>{item.unitsSold}</p>
                      <p>${item.Revenue}</p>
                      <p>${item.ADR}</p>
                      <p>${item.Revpar.revpar}</p>
                      <p>{item.Occupancy}%</p>
                    </div>
                  ) : null}
                  {props.roomsData.map((room, roomIndex) => {
                    return Object.keys(props.OTAPrices).length !== 0 ? (
                      <div>
                        <div
                          className="table-grid-column"
                          onClick={() => {
                            tableColumnClicked(
                              item.date.split("-")[0] +
                                "-" +
                                item.date.split("-")[1] +
                                "-" +
                                item.date.split("-")[2],
                              room.roomGroupName,
                              room.roomGroupId
                            );
                          }}
                        >
                          <div className="optPrice">&nbsp;</div>
                          <div className="optPrice">
                            {room.roomGroupName in props.OTAPrices
                              ? dateIndex in
                                props.OTAPrices[room.roomGroupName]
                                ? props.OTAPrices[room.roomGroupName][dateIndex]
                                    .optionprice1user
                                : "--/--"
                              : "--/--"}
                          </div>
                          <div className="optPrice">
                            {room.roomGroupName in props.OTAPrices
                              ? dateIndex in
                                props.OTAPrices[room.roomGroupName]
                                ? props.OTAPrices[room.roomGroupName][dateIndex]
                                    .optionprice2user
                                : "--/--"
                              : "--/--"}
                          </div>
                          <div className="optPrice">
                            {room.roomGroupName in props.OTAPrices
                              ? dateIndex in
                                props.OTAPrices[room.roomGroupName]
                                ? props.OTAPrices[room.roomGroupName][dateIndex]
                                    .optionprice3user
                                : "--/--"
                              : "--/--"}
                          </div>
                          <div className="optPrice">
                            {room.roomGroupName in props.OTAPrices
                              ? dateIndex in
                                props.OTAPrices[room.roomGroupName]
                                ? props.OTAPrices[room.roomGroupName][dateIndex]
                                    .optionprice4user
                                : "--/--"
                              : "--/--"}
                          </div>
                          <div className="optPrice">
                            {room.roomGroupName in props.OTAPrices
                              ? dateIndex in
                                props.OTAPrices[room.roomGroupName]
                                ? props.OTAPrices[room.roomGroupName][dateIndex]
                                    .optionprice5user
                                : "--/--"
                              : "--/--"}
                          </div>
                          <div className="optPrice">
                            {room.roomGroupName in props.OTAPrices
                              ? dateIndex in
                                props.OTAPrices[room.roomGroupName]
                                ? props.OTAPrices[room.roomGroupName][dateIndex]
                                    .optionprice6user
                                : "--/--"
                              : "--/--"}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p>Loading... </p>
                    );
                  })}
                </div>
              );
            })
          : progress}
      </div>
      {roomPriceUpdate ? (
        <IndividualCellPopup
          roomFlag={roomPriceUpdate}
          onChange={setRoomPriceUpdate}
          onChange1={props.onChange}
          dateValue={props.dateValue}
          date={byDate}
          priceUpdateRoomName={priceUpdateRoomName}
          roomId={priceUpdateRoomId}
          optionSelected={props.optionSelected}
        />
      ) : null}
    </div>
  );
}

export default PricingTable;
